import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.Apaufo,
      distributedTracingMode: DistributedTracingModes.W3C,
      disableCorrelationHeaders: false,
      enableCorsCorrelation: true,
      enableAutoRouteTracking: true,
      disableCookiesUsage: true,
      correlationHeaderExcludedDomains: ['geolocation.onetrust.com', 'stats.g.doubleclick.net','cdn.cookielaw.org','www.google-analytics.com']
    }
  });
  constructor(private router: Router) {

    if (!environment.devModeEnabled) {
      this.appInsights.loadAppInsights();
    }

  }

  get AppInsights(): any {
    return this.appInsights;

  }
  trackPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }
  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception, severityLevel });
  }
  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message }, properties);
  }
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name, average }, properties);
  }
  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name }, properties);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }
  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }
    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }

}
