import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CcModalContainerComponent } from '@app/components/modal/modal.component.container';
import { CcModal_ } from '@app/components/modal/modal.component.service';

@NgModule({
  declarations: [CcModalContainerComponent],
  imports: [CommonModule, OverlayModule, PortalModule],
  exports: [CcModalContainerComponent],
  providers: [CcModal_],
  entryComponents: [CcModalContainerComponent]
})
export class CcModalBoxModule {
  static forRoot(): ModuleWithProviders<CcModalBoxModule> {
    return {
      ngModule: CcModalBoxModule,
      providers: [CcModal_]
    };
  }
}
