import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { FactorRevisionStatusInfo } from '../models/misc/general';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  // Observable sources
  public singleDdlChange = new Subject<any>();
  public jsonCompareResult = new Subject<any>();
  public settingsTabListener = new Subject<any>();
  public priceTypeSelectionResult = new Subject<any>();
  public matTableSearch = new Subject<any>();
  public matTableSearchCust = new Subject<any>();
  public eventEmitListener = new Subject<any>();
  public refreshFactorListener = new Subject<any>();
  public travelFactorChanged = new Subject<any>();
  public userPositionIndicatorListener = new Subject<any>();
  public estimateDetailsListner = new Subject<any>();
  public treeViewUpdate = new Subject<any>();
  public closeFilterListener = new Subject<any>();
  public includeTravel = new Subject<any>();
  public partsChangeEmitListener = new Subject<any>();
  private formChangesListener = new BehaviorSubject<any>({});
  public isServiceDetailsScrolled = new Subject<any>();
  public defaultWGRoleListener = new Subject<any>();
  private repricingEstimatesProgressListener: Subject<FactorRevisionStatusInfo[]> = new Subject<FactorRevisionStatusInfo[]>();
  private wgChangeListener= new Subject<any>();
  private isCompareEstimateScrolled = new Subject<any>();
  private treeViewUpdatebyBack = new Subject<any>();
  private treeViewUpdatebyData = new Subject<any>();
  private freData = new Subject<any>();
  private mpeFlag = new BehaviorSubject<boolean>(true);
  private soFlag = new BehaviorSubject<boolean>(false);
  private tmiData = new Subject<any>();
  public intervalDetailsData = new Subject<any>();
  public contingencyData = new Subject<any>();
  private productUploadData = new Subject<any>();
  private mpEstimateResponse = new Subject<any>();
  private mpeBuilderXMLUploadSummarized = new Subject<any>();
  private selectedRIVariability = new Subject<string>();
  private addedFREServiceOptions = new Subject<any>();
  public userDetails = new BehaviorSubject<any>([]);

  // Observable  streams
  singleDdlChange$ = this.singleDdlChange.asObservable();
  jsonCompareResult$ = this.jsonCompareResult.asObservable();
  settingsTabListener$ = this.settingsTabListener.asObservable();
  priceTypeSelectionResult$ = this.priceTypeSelectionResult.asObservable();
  matTableSearch$ = this.matTableSearch.asObservable();
  matTableSearchCust$ = this.matTableSearchCust.asObservable();
  eventEmitListener$ = this.eventEmitListener.asObservable();
  refreshFactorListener$ = this.refreshFactorListener.asObservable();
  travelFactorChanged$ = this.travelFactorChanged.asObservable();
  userPositionIndicatorListener$ = this.userPositionIndicatorListener.asObservable();
  estimateDetailsListner$ = this.estimateDetailsListner.asObservable();
  treeViewUpdate$ = this.treeViewUpdate.asObservable();
  closeFilterListener$ = this.closeFilterListener.asObservable();
  includeTravel$ = this.includeTravel.asObservable();
  partsChangeEmitListener$ = this.partsChangeEmitListener.asObservable();
  engorMach: any;
  formChangesObservable = this.formChangesListener.asObservable();
  isServiceDetailsScrolled$ = this.isServiceDetailsScrolled.asObservable();
  defaultWGRoleListener$ = this.defaultWGRoleListener.asObservable();
  repricingEstimatesProgressListener$ = this.repricingEstimatesProgressListener.asObservable();
  wgChangeListener$ = this.wgChangeListener.asObservable(); 
  isCompareEstimateScrolled$ = this.isCompareEstimateScrolled.asObservable();
  treeViewUpdatebyBack$ = this.treeViewUpdatebyBack.asObservable();
  treeViewUpdatebyData$ = this.treeViewUpdatebyData.asObservable();
  intervalDetailsData$ = this.intervalDetailsData.asObservable();
  freData$ = this.freData.asObservable();
  mpeFlag$ = this.mpeFlag.asObservable();
  soFlag$ = this.soFlag.asObservable();
  tmiData$ = this.tmiData.asObservable();
  productUploadData$ = this.productUploadData.asObservable();
  mpEstimateResponse$ = this.mpEstimateResponse.asObservable();
  contingencyData$ = this.contingencyData.asObservable();
  mpeBuilderXMLUploadSummarized$ = this.mpeBuilderXMLUploadSummarized.asObservable();
  selectedRIVariability$ = this.selectedRIVariability.asObservable();
  addedFREServiceOptions$ = this.addedFREServiceOptions.asObservable();
  userDetails$ = this.userDetails.asObservable();

  pdsIdListData1: any[];
  
  setEngorMach(engorMach) {
    this.engorMach = engorMach;
  }

  getEngorMach() {
    return this.engorMach;
  }

  sendMessage(message: any, messageType: string) {
    switch (messageType) {
      case 'singleDdlChange':
        this.singleDdlChange.next({ message });
        break;
      case 'jsonCompareResult':
        this.jsonCompareResult.next({ message });
        break;
      case 'settingsTabListener':
        this.settingsTabListener.next({ message });
        break;
      case 'priceTypeSelection':
        this.priceTypeSelectionResult.next({ message });
        break;
      case 'matTableSearch':
        this.matTableSearch.next({ message });
        break;
      case 'matTableSearchCust':
        this.matTableSearchCust.next({ message });
        break;
      case 'eventEmitListen':
        this.eventEmitListener.next(message);
        break;
      case 'refreshFactorListener':
        this.refreshFactorListener.next(message);
        break;
      case 'travelFactorChanged':
        this.travelFactorChanged.next(message);
        break;
      case 'saveAndExitEstimate': // to detect saveAndExit button click
        this.eventEmitListener.next(message);
        break;
      case 'estimateDetailsListner':
        this.estimateDetailsListner.next({ message });
        break;
      case 'treeViewUpdate':
        this.treeViewUpdate.next({ message });
        break;
      case 'closeFilterListener':
        this.closeFilterListener.next(message);
        break;
      case 'partsUpdateListener':
        this.partsChangeEmitListener.next(message);
        break;
      case 'includeTravel':
        this.includeTravel.next(message);
        break;
      case 'isServiceDetailsScrolled':
        this.isServiceDetailsScrolled.next(message);
        break;
      case 'defaultWGRoleChanged':
        this.defaultWGRoleListener.next(message);
        break;
      case 'repricingEstimatesProgressListener': 
        this.repricingEstimatesProgressListener.next(message);
        break;
      case 'wgChangeListener': 
        this.wgChangeListener.next(message);
        break;    
      case 'isCompareEstimateScrolled':
        this.isCompareEstimateScrolled.next(message);
        break;
      case 'treeViewUpdatebyBack':
        this.treeViewUpdatebyBack.next(message);
        break;
      case 'treeViewUpdatebyData':
        this.treeViewUpdatebyData.next(message);
        break;
      case 'freData':
        this.freData.next(message);
        break;
      case 'tmiData':
        this.tmiData.next(message);
        break;
      case 'productUploadData':
        this.productUploadData.next(message);
        break;
      case 'mpeResponse':
        this.mpEstimateResponse.next(message);
        break;
    }
  }

  setFormChangesStream(value: any) {
    this.formChangesListener.next(value);
  }

  setmpeFlag(value){
    this.mpeFlag.next(value);
  }
  setSOflag(value){
    this.soFlag.next(value);
  }

  mpeBuilderXMLSummarized = (fileSummary) => {
    this.mpeBuilderXMLUploadSummarized.next(fileSummary);
  }

  setRIVariabilityFactor = (selectedFactor) => {
    this.selectedRIVariability.next(selectedFactor);
  }

  updateAddedFREServiceOptions(addedFREServiceOptions) {
    this.addedFREServiceOptions.next(addedFREServiceOptions);
  }

}
