import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewRef,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { NgbModal, NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ResizeEvent } from "angular-resizable-element";
import * as _ from "lodash";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { FormatCellPipe } from "@app/common/pipes/format-cell.pipe";
import { CcModal_ } from "@app/components/modal/modal.component.service";
import { ViewReportOptionsComponent } from "@app/estimate/view-report-options/view-report-options.component";
import { CommonService } from "@app/services/common/common.service";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { UserService } from "@app/services/misc/user.service";
import { WGRole } from "@app/services/models/roles";
import * as AppConstant from "@app/services/shared/app-constants";
import * as constants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";

export type SortDirection = "asc" | "desc" | "";
const rotate: { [key: string]: SortDirection } = {
  asc: "desc",
  desc: "",
  "": "asc",
};

export interface SortEvent {
  column: string;
  direction: SortDirection;
}

@Directive({
  selector: "[appSortable]",
})
export class NgbdSortableHeaderDirective {
  @Input() appSortable: string;
  @Input() direction: SortDirection = "";
  @Output() sort = new EventEmitter<SortEvent>();
  @HostBinding("class.asc") dir = this.direction;

  @HostListener("click") rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.appSortable, direction: this.direction });
  }
}

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit, OnDestroy {
  @ViewChildren(NgbdSortableHeaderDirective)
  headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(NgbPopover) popovers: QueryList<NgbPopover>;
  @ViewChild(MatTable, { read: ElementRef, static: true })
  private matTableRef: ElementRef;

  @Input() columnHeaders: any[];
  @Input() tableData: any[];
  @Input() showFilterIcon = false;
  @Input() showeyeIcon = false;
  @Input() pagination = false;
  @Input() parentFunction = Function;
  @Input() isMobOrTab = false;
  @Input() showhover = false;
  @Input() showMoreIcon = false;
  @Input() moreOptions: any[];
  @Output() moreClickEvent = new EventEmitter();
  @Input() showEditIcon = false;
  @Input() showEditIconOnly = false;
  @Input() showDeleteIcon = false;
  @Output() editEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Input() linkFunction = Function;
  @Output() viewFunction = new EventEmitter();
  @Output() editFunction = new EventEmitter();
  @Output() copyFunction = new EventEmitter();
  @Output() deleteFunction = new EventEmitter();
  @Output() archiveFunction = new EventEmitter();
  @Output() checkboxHandler = new EventEmitter();

  @Output() checkboxEventHandler = new EventEmitter();
  @Output() selectedRowHandler = new EventEmitter();

  @Input() disabled = false;
  @Input() checked: any;
  @Input() showCheckbox = false;
  @Input() isEdit = true;
  @Input() prefernceName: string;
  @Input() shownorecord: boolean = true;
  @Input() defaultRadioSelectedIndex = 0;
  @Input() editOnly = false;
  @Input() disableEditIcon = false;

  @Input() defaultCheckboxArray = [];
  @Input() showMultipleCheckbox = false;

  // new UI revamp Changes----start
  @Input() showColumnLock = false;
  @Input() showFullTableMoreActionBtn = false;
  @Input() hideEyeIconFromHeader = false;
  @Input() isWritable = false;
  @Input() isStickyEyeIcon = false;
  @Output() estimateListColumnPopover = new EventEmitter(); // ui redesign popover change
  // new UI revamp Changes----end

  @Input() isFromGlobal;
  @Input() insidePopup = false;
  private dataSubscribe: any;
  showTable = false;
  originalData: any[];
  prefernceData = {
    Name: "",
    Settings: [],
  };
  sortDirection = true;
  keys: string[];
  maxDate;
  minDate;
  byName = false;
  startUsage = false;
  endUsage = false;
  submit = false;
  startEndError = false;
  startInvalidError = false;
  startNullError = false;
  endInvalidError = false;
  endNullError = false;
  show = false;
  direction = "asc";
  displayColumns = [];
  page = 1;
  isMobile = false;
  totalLength: number;
  pageLimit = 25;
  pageStartIndex = 1;
  hoverData: any;
  rowSelected: any;
  isFilterPopUpIsOn = false;
  backupData: any[];
  workGroup: any = ["Caterpillar", "Joe Smith", "Toromont"];
  searchInput: string;
  popover: any;
  sourceName = AppConstant.sourceName;
  dataTable: any;
  viewEnabled = false;
  editEnabled = false;
  copyEnabled = false;
  shareEnabled = false;
  deleteEnabled = false;
  reportEnabled = false;
  archiveEnabled = false;
  currentRow: any;
  column = [];
  tableWidth: number;
  currentResizeIndex: any;
  startX: any;
  pressed: boolean;
  startWidth: any;
  start: HTMLTableHeaderCellElement;
  isResizingRight: boolean;
  resizableMousemove: Function;
  resizableMouseup: Function;
  columns = [];
  resizer: any;
  widthChange: number;
  displayedColumns: string[] = [];
  scrollHandleDisplayed = false;
  filterPopover: any;
  dataSource = new MatTableDataSource();
  pager: any;
  itemsPerPage: number;
  planNumber = 401;
  showMoredetails;
  lastprefernceData;
  checkboxChecked;
  showEditOption = true;
  readonly = WGRole.ReadOnly;
  @Input() highlight = false;
  lockedColumnOrder = [];

  checkboxValue = {
    value: [],
    checked: true,
  };
  calculatedMenuColumnWidth = 0;
  resizeLineMargin = 0;
  mousePos = null;
  isResizing = false;

  private searchSub: any;
  @Input() selectedRow;
  @Input() enableSort: boolean = true;
  isUserOrgIntermediate: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    public translate: TranslateService,
    private router: Router,
    private renderer: Renderer2,
    private formatPipe: FormatCellPipe,
    private subjectService: SubjectService,
    public dataService: DataService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private estimateService: EstimatePricingService,
    public snackBar: MatSnackBar,
    private userService: UserService,
    private ref: ChangeDetectorRef,
    public modal: CcModal_
  ) {
    this.isUserOrgIntermediate = this.dataService.isUserOrgIntermediate;
  }

  ngOnInit() {
    this.subjectService.sendMessage(
      { recordsFetched: false },
      "eventEmitListen"
    );
    fromEvent(document.body, "mousemove").subscribe((e) => {
      this.mousePos = e;
    });
    this.getPrefernces();
    this.initListenChange();
    this.priceTypeCall();
    this.setDisplayedColumns();
    this.searchSub = this.subjectService.matTableSearch$
      .pipe(debounceTime(500))
      .subscribe((searchTextValue) => {
        if (document.getElementById("common-data-table")) {
          document.getElementById("common-data-table").style.width =
            "max-content";
        }
        if (!(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
        this.searchTable(searchTextValue.message);
      });
    this.backupData = { ...this.tableData };
    this.itemsPerPage = 100;
    if (this.showeyeIcon) {
      this.generateColumnHeader();
    } else {
      this.columnHeaders.forEach((col) => {
        this.displayColumns.push(col);
      });
    }
    if (this.pagination) {
      this.totalLength = this.tableData.length;
    }
    if (this.moreOptions) {
      this.generateMoreOptions();
    }
  }

  initListenChange() {
    this.subjectService.eventEmitListener$.subscribe((value) => {
      if (value && value.filterFormvalue) {
        this.applyFilter(value.filterFormvalue);
      }

      if (value && value.cancelTriggered) {
        this.closeFilter();
      }
    });
  }

  priceTypeCall() {
    this.subjectService.priceTypeSelectionResult$.subscribe((data) => {
      this.setDisplayedColumns();
    });

    this.subjectService.eventEmitListener$.subscribe((data) => {
      if (data && data.refreshTable) {
        if (this.pagination) {
          this.setPaginatedData();
        } else {
          this.dataSource.data = this.tableData;
          this.totalLength = this.tableData.length;
        }
        this.setTableDisplay();
      }
      if (data && data.pagination) {
        this.pager = data.pagination;
        this.setPaginatedData();
      }
    });
  }

  ngOnChanges(changes: any) {
    if (changes && changes.tableData) {
      if (this.moreOptions) {
        this.generateMoreOptions();
      }
      if (!this.pagination) {
        this.dataSource.data = changes.tableData.currentValue;
      }
      this.tableData = changes.tableData.currentValue;
      if (changes.tableData.currentValue) {
        this.totalLength = changes.tableData.currentValue.length;
      }
      if (
        this.tableData &&
        this.tableData.length > 0 &&
        this.columns.length === 0
      ) {
        this.setDisplayedColumns();
      }
      this.originalData = _.clone(changes.tableData.currentValue);
      this.setTableDisplay();
      this.setPaginatedData();
      this.sortingTables();
    }
  }
  ngAfterContentChecked() {
    const arr = [];
    for (const key in this.columnHeaders) {
      if (this.columnHeaders[key].show) {
        arr.push({
          label: this.columnHeaders[key].value,
          width: this.columnHeaders[key].width,
        });
      }
    }
    const arr1 = [];
    // tslint:disable-next-line: forin
    for (const i in arr) {
      if (arr[i]) {
        arr1.push({
          Name: arr[i].label,
          Value: arr[i].width,
        });
      }
    }
    this.prefernceData.Name = this.prefernceName;
    this.prefernceData.Settings = arr1;
    this.calculateMenuColumnWidth();
  }

  postPrefernce() {
    const sub = this.userService
      .saveUserPreferences(this.prefernceData)
      .subscribe(() => {
        const data = this.dataService.userPrefenceData;
        const userData = data.filter((elem) => elem.Name == this.prefernceName);
        if (userData.length != 0) {
          const index = this.dataService.userPrefenceData.indexOf(userData[0]);
          this.dataService.userPrefenceData.splice(index, 1);
        }
        this.dataService.userPrefenceData.push({
          Name: this.prefernceName,
          Settings: this.prefernceData.Settings,
        });
        sub.unsubscribe();
      });
  }

  getPrefernces() {
    if (this.prefernceName) {
      const data = this.dataService.userPrefenceData;
      const userData = data.filter((elem) => elem.Name == this.prefernceName);
      if (userData.length == 0) {
        this.dataSubscribe = this.userService
          .getUserPreferences(this.prefernceName)
          .subscribe(
            (response) => {
              this.subjectService.sendMessage(
                { recordsFetched: true },
                "eventEmitListen"
              );
              this.lastprefernceData = response.Settings;
              this.dataService.userPrefenceData.push({
                Name: this.prefernceName,
                Settings: this.lastprefernceData,
              });
              this.setPreferenceData();
              this.callDisplayedColumns();
              this.showTable = true;
            },
            (err) => {
              if (this.tableData && this.tableData.length > 0) {
                this.setDisplayedColumns();
                this.ref.detectChanges();
                this.showTable = true;
              }
            }
          );
      } else {
        this.subjectService.sendMessage(
          { recordsFetched: true },
          "eventEmitListen"
        );
        this.lastprefernceData = userData[0].Settings;
        this.setPreferenceData();
        this.ref.detectChanges();
        this.callDisplayedColumns();
        this.showTable = true;
        this.ref.detectChanges();
      }
    } else {
      this.showTable = true;
      this.setDisplayedColumns();
    }
  }

  setPreferenceData() {
    if (this.lastprefernceData.length > 0) {
      for (const key in this.columnHeaders) {
        if (key) {
          this.columnHeaders[key].show = false;
          for (const keys in this.lastprefernceData) {
            if (
              this.lastprefernceData[keys].Name ===
              this.columnHeaders[key].value
            ) {
              this.columnHeaders[key].show = true;
              this.settingPreference(key, keys);
            }
          }
        }
      }
    }
  }

  setPaginatedData() {
    if (this.pager && this.pagination) {
      const pagedData = this.tableData.slice(
        this.pager.startIndex,
        this.pager.endIndex + 1
      );
      this.itemsPerPage = this.pager.pageSize;
      this.dataSource.data = pagedData;
      this.totalLength = this.tableData.length;
      this.setTableDisplay();
    }
  }

  setDisplayedColumns() {
    this.displayedColumns = [];
    this.columns = [];
    if (this.showCheckbox) {
      this.displayedColumns.push("checkbox");
    }
    if (this.showMultipleCheckbox) {
      this.displayedColumns.push("multiplecheckbox");
    }
    this.columnHeaders.forEach((column, index) => {
      if (column.show) {
        column.index = index;
        column.order = null;
        column.sortActive = false;
        this.columns.push(column);
        this.displayedColumns.push(column.value);
      }
    });
    if (this.showeyeIcon) {
      this.displayedColumns.push("eyeIcon");
    }
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  generateMoreOptions() {
    this.viewEnabled = false;
    this.editEnabled = false;
    this.copyEnabled = false;
    this.shareEnabled = false;
    this.deleteEnabled = false;
    this.reportEnabled = false;
    this.archiveEnabled = false;
    this.moreOptions.forEach((option) => {
      if (option.label === "View") {
        this.viewEnabled = true;
      } else if (option.label === "Edit") {
        this.editEnabled = true;
      } else if (option.label === "Copy") {
        this.copyEnabled = true;
      } else if (option.label === "Share") {
        this.shareEnabled = true;
      } else if (option.label === "Delete") {
        this.deleteEnabled = true;
      } else if (option.label === "Generate Report") {
        this.reportEnabled = true;
      } else if (option.label === "Archive") {
        this.archiveEnabled = true;
      }
    });
  }

  getStyleVal(elm, css) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }

  generateColumnHeader() {
    this.displayColumns = [];
    this.columnHeaders.forEach((col) => {
      if (col.show) {
        this.displayColumns.push(col);
      }
    });
  }

  togglePopover(flag: boolean, popover) {
    this.popover = popover;
    if (!flag) {
      if (this.checkboxChecked) {
        this.postPrefernce();
        this.checkboxChecked = false;
      }
      this.setDisplayedColumns();
      this.calculateMenuColumnWidth();
    }
  }

  headerChange(event, column) {
    this.checkboxChecked = true;
    let count = 0;
    let tempCol;
    if (!event) {
      this.columnHeaders.forEach((col) => {
        if (col.show && col.value !== "eye") {
          tempCol = col;
          count++;
        }
      });
      if (count === 1) {
        tempCol.disabled = true;
      }
    } else {
      this.columnHeaders.forEach((col) => {
        if (!col.alwaysDisable) {
          col.disabled = false;
        }
      });
    }
    this.estimateListPopOver();
  }

  sortAscDesc(direction, column, data) {
    const n = this.tableData.length;
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < n - i - 1; j++) {
        if (direction === "asc") {
          if (data[j][column] > data[j + 1][column]) {
            const temp = data[j];
            data[j] = data[j + 1];
            data[j + 1] = temp;
          }
        } else {
          if (data[j][column] < data[j + 1][column]) {
            const temp = data[j];
            data[j] = data[j + 1];
            data[j + 1] = temp;
          }
        }
      }
    }
  }

  getCssClass(row: any, key: any) {
    return this.parentFunction(row, key);
  }

  generateId(val) {
    return val + "-id";
  }

  showpopover(row, index, popover, key) {
    this.rowSelected = row;
  }

  generateSerialNumber(estimate) {
    const arr = [];
    if (estimate.Range) {
      // tslint:disable-next-line: max-line-length
      return (
        estimate.Prefix +
        estimate.Range +
        " - " +
        estimate.Prefix +
        estimate.Range
      );
    }
  }

  recordShown(popover) {
    this.filterPopover = popover;
    this.isFilterPopUpIsOn = true;
  }

  recordHidden() {
    this.isFilterPopUpIsOn = false;
  }
  recordShownHover(popover) {
    if (popover.isOpen() && this.isFilterPopUpIsOn) {
      popover.close();
    }
  }

  dateConverter(date) {
    date = new Date(date);
    this.datePipe.transform(date, "MM-dd-yyyy");
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    const day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    const convertedDate = year + "/" + month + "/" + day;

    return convertedDate;
  }

  applyFilter(filterForm) {
    let value = "";
    const formObj = {
      formName: "Data Table Filter",
      formContent: null,
      formContent2: null,
      formStatus: "success",
    };
    const filters = {
      byName: (estimate, val) =>
        estimate &&
        estimate.PlanName &&
        estimate.PlanName.toLowerCase().includes(val),
      createdDate: (estimate, val) =>
        new Date(
          estimate &&
            estimate.CreatedOn.substr(
              0,
              estimate && estimate.CreatedOn.indexOf("T")
            )
        ).getTime() >= new Date(this.dateConverter(val.begin)).getTime() &&
        new Date(
          estimate &&
            estimate.CreatedOn.substr(
              0,
              estimate && estimate.CreatedOn.indexOf("T")
            )
        ).getTime() <= new Date(this.dateConverter(val.end)).getTime(),
      lastUpdatedDate: (estimate, val) =>
        new Date(
          estimate &&
            estimate.LastModifiedOn.substr(
              0,
              estimate && estimate.LastModifiedOn.indexOf("T")
            )
        ).getTime() >= new Date(this.dateConverter(val.begin)).getTime() &&
        new Date(
          estimate &&
            estimate.LastModifiedOn.substr(
              0,
              estimate && estimate.LastModifiedOn.indexOf("T")
            )
        ).getTime() <= new Date(this.dateConverter(val.end)).getTime(),
      slNoPrefix: (estimate, val) =>
        estimate &&
        estimate.Prefix &&
        estimate.Prefix.toLowerCase().includes(val),
      status: (estimate, val) =>
        estimate &&
        estimate.Status &&
        estimate.Status.toLowerCase().includes(val),
      startUsage: (estimate, val) => estimate && estimate.Start === Number(val),
      endUsage: (estimate, val) => estimate && estimate.End === Number(val),
      createdBy: (estimate, val) =>
        estimate &&
        estimate.CreatedBy &&
        estimate.CreatedBy.toLowerCase().includes(val),
      manufacturer: (estimate, val) =>
        estimate &&
        estimate.ManufacturerName &&
        estimate.ManufacturerName.toLowerCase().includes(val),
      lastUpdatedBy: (estimate, val) =>
        estimate &&
        estimate.ModifiedBy &&
        estimate.ModifiedBy.toLowerCase().includes(val),
      family: (estimate, val) =>
        estimate &&
        estimate.FamilyName &&
        estimate.FamilyName.toLowerCase().includes(val),
      model: (estimate, val) =>
        estimate &&
        estimate.Model &&
        estimate.Model.toLowerCase().includes(val),
    };

    // tslint:disable-next-line: max-line-length
    Object.keys(filterForm.value).forEach((key, index, array) => {
      if (
        filterForm.value[key] === "" ||
        filterForm.value[key] === undefined ||
        filterForm.value[key] === null
      ) {
        delete filterForm.value[key];
      } else {
        formObj.formContent = filterForm.value[key];
        const temp =
          index < array.length - 1
            ? "User Entered Value |"
            : "User Entered Value";
        value = value + temp;
      }
    });

    filterForm.value = Object.keys(filterForm.value).reduce((n, k) => {
      n[k] =
        typeof filterForm.value[k] == "string"
          ? filterForm.value[k].toLowerCase()
          : filterForm.value[k];
      return n;
    }, {});

    const checkIfItemHasObject = (item, val) => {
      return (
        Object.keys(val).filter((key) => filters[key](item, val[key]))
          .length === Object.keys(val).length
      );
    };
    formObj.formContent2 = value;
    this.subjectService.setFormChangesStream({
      isFormSubmitted: true,
      formValue: formObj,
    });
    const res = this.originalData.filter((estimate) =>
      checkIfItemHasObject(estimate, filterForm.value)
    );
    this.tableData = res;
    this.dataSource.data = this.tableData;
    this.totalLength = res.length;
    this.setTableDisplay();
  }
  closeFilter() {
    this.tableData = this.originalData;
    this.dataSource.data = this.tableData;
    this.totalLength = this.dataSource.data.length;
  }

  onStartUsageBlur() {
    this.startUsage = true;
  }

  onEndUsageBlur() {
    this.endUsage = true;
  }

  isMobileOrTablet() {
    // tslint:disable-next-line: max-line-length
    if (
      constants.mobOrTabCheckReg1.test(navigator.userAgent) ||
      // tslint:disable-next-line: max-line-length
      constants.mobOrTabCheckReg2.test(navigator.userAgent.substr(0, 4))
    ) {
      this.isMobOrTab = true;
    }
  }

  highlightRow(row) {
    if (row && row.Archived) {
      return "highlight-row";
    }
    return "";
  }
  moreClick(target) {
    const dataObj = {
      target,
      data: this.rowSelected,
    };
    this.moreClickEvent.emit(dataObj);
  }

  assignSelectedRow(row) {
    this.currentRow = row;
    this.showMoredetails = row.Archived;
    if (row && row.Status === "Finalized") {
      this.showEditOption = false;
    } else if (
      row &&
      row.DataHeader &&
      row.DataHeader.IsCustom &&
      this.isWritable
    ) {
      this.setEditAndDeleteOption();
    } else if (
      row &&
      row.DataHeader &&
      ((!row.DataHeader.IsCustom && !this.isFromGlobal) || !this.isWritable)
    ) {
      this.showEditOption = false;
      this.deleteEnabled = false;
    } else if (
      row &&
      row.DataHeader &&
      ((!row.DataHeader.IsCustom && this.isFromGlobal) || !this.isWritable)
    ) {
      this.setEditAndDeleteOption();
    } else {
      this.showEditOption = true;
    }
  }

  setEditAndDeleteOption() {
    this.showEditOption = true;
    this.deleteEnabled = true;
  }

  viewData() {
    this.viewFunction.emit(this.currentRow);
  }

  editData() {
    this.editFunction.emit(this.currentRow);
  }

  copyData() {
    this.copyFunction.emit(this.currentRow);
  }

  deleteData() {
    this.deleteFunction.emit(this.currentRow);
  }

  edit(target, j) {
    if (this.isWritable && !this.disableEditIcon) {
      const dataObj = {
        j,
        row: target,
      };
      this.editEvent.emit(dataObj);
    }
  }

  delete(target, j) {
    if (this.isWritable && !this.disableEditIcon) {
      const dataObj = {
        j,
        row: target,
      };
      this.deleteEvent.emit(dataObj);
    }
  }

  // reportgenerate
  showGenerateReportModal() {
    const modalRef = this.modal.openModal(ViewReportOptionsComponent, {
      backdropClass: "cc-modal-full-window-backdrop",
      panelClass: "cc-modal-full-window",
      hasScroll: true,
      data: { tableList: this.currentRow.PlanNumber },
    });

    modalRef.afterClosed().subscribe((data) => {});
  }

  setTableResize(tableWidth: number) {
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
    let totWidth = 0;
    this.columns.forEach((column) => {
      totWidth += column.width;
    });
    const len = this.columns.length - 1;
    const scale = (tableWidth - len) / totWidth;
    this.columns.forEach((column) => {
      column.width *= scale;
      this.setColumnWidth(column);
    });
  }

  archiveData() {
    this.archiveFunction.emit(this.currentRow);
  }
  onEstimateNameBlur() {
    this.byName = true;
  }

  onResizeColumn(event: any, index: number) {
    this.scrollHandleDisplayed = true;
    this.checkResizing(event, index);
    this.currentResizeIndex = index;
    this.pressed = true;
    this.startX = event.pageX;
    this.startWidth = this.columns[index].width;
    event.preventDefault();
    this.mouseMove(index, this.columns[index]);
  }

  private checkResizing(event, index) {
    const cellData = this.getCellData(index);
    if (
      index === 0 ||
      (Math.abs(event.pageX - cellData.right) < cellData.width / 2 &&
        index !== this.columns.length - 1)
    ) {
      this.isResizingRight = true;
    } else {
      this.isResizingRight = false;
    }
  }

  private getCellData(index: number) {
    const headerRow = this.matTableRef.nativeElement.children[0];
    const row = headerRow.children[0];
    let cell = null;
    if (this.showCheckbox) {
      cell = row.children[index + 1];
    } else if (this.showMultipleCheckbox) {
      cell = row.children[index + 1];
    } else {
      cell = row.children[index];
    }
    return cell.getBoundingClientRect();
  }

  mouseMove(index: number, column) {
    this.resizableMousemove = this.renderer.listen(
      "document",
      "mousemove",
      (event) => {
        if (this.pressed && event.buttons) {
          const dx = this.isResizingRight
            ? event.pageX - this.startX
            : -event.pageX + this.startX;
          const width = this.startWidth + dx;
          if (this.currentResizeIndex === index && width > 50) {
            this.setColumnWidthChanges(index, width);
          }
        }
      }
    );
    this.resizableMouseup = this.renderer.listen(
      "document",
      "mouseup",
      (event) => {
        this.postPrefernce();
        this.scrollHandleDisplayed = false;
        this.removeHandle(column.value);
        if (this.pressed) {
          this.pressed = false;
          this.currentResizeIndex = -1;
          this.resizableMousemove();
          this.resizableMouseup();
        }
      }
    );
  }

  setColumnWidthChanges(index: number, width: number) {
    const orgWidth = this.columns[index].width;
    const dx = width - orgWidth;
    if (dx !== 0) {
      const j = this.isResizingRight ? index + 1 : index - 1;
      const newWidth = this.columns[j].width - dx;
      if (newWidth > 50) {
        this.columns[index].width = width;
        this.setColumnWidth(this.columns[index]);
        this.columns[j].width = newWidth;
        this.setColumnWidth(this.columns[j]);
      }
    }
  }

  setColumnWidth(column: any) {
    const columnEls = Array.from(
      document.getElementsByClassName("mat-column-" + column.value)
    );
    columnEls.forEach((el: HTMLDivElement) => {
      el.style.width = column.width + "px";
    });
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.calculateMenuColumnWidth();
  }

  addHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName("mat-column-" + id)[0];
      element.classList.add("border-right-style");
      const scrollClass = element.getElementsByClassName("scroll-handle")[0];
      scrollClass.classList.add("display-class");
    }
  }

  removeHandle(id) {
    if (!this.scrollHandleDisplayed) {
      const element = document.getElementsByClassName("mat-column-" + id)[0];
      element.classList.remove("border-right-style");
      const scrollClass = element.getElementsByClassName("scroll-handle")[0];
      scrollClass.classList.remove("display-class");
    }
  }

  checkTrueOrFalse(element, column) {
    if (this.formatPipe.transform(element[column.keyPath], column, element)) {
      return true;
    } else {
      return false;
    }
  }

  checkboxValueChanged(element) {
    this.checkboxHandler.emit(element);
  }

  multicheckboxValueChanged(element, val) {
    this.checkboxValue.value = element;
    this.checkboxValue.checked = val.target.checked;
    this.checkboxEventHandler.emit(this.checkboxValue);
  }

  onSearch(searchTextValue: string, event: Event) {
    event.stopPropagation();
    this.subjectService.sendMessage(searchTextValue, "matTableSearch");
  }

  searchTable(searchTerm) {
    if (searchTerm) {
      if (document.getElementById("common-data-table")) {
        document.getElementById("common-data-table").style.width = "100%";
      }
      const results = this.commonService.matTableSearch(
        searchTerm,
        this.originalData,
        this.columnHeaders
      );
      this.tableData = results;
      this.totalLength = results.length;
      if (!(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    } else {
      this.tableData = this.originalData;
    }
    if (this.pagination) {
      this.setPaginatedData();
    } else {
      this.totalLength = this.tableData.length;
      this.dataSource.data = this.tableData;
      this.setTableDisplay();
    }
  }

  sortTable(column) {
    if (this.enableSort) {
      this.resetSort();
      column.order = !column.order;
      column.sortActive = true;
      const unordered = [];
      const orderedArr = [];
      this.tableData.forEach((row, index) => {
        const cellData = this.formatPipe.transform(undefined, column, row);
        let rawData;
        if (column.hasOwnProperty("currency")) {
          rawData = this.formatPipe.getAmount(column, row);
        } else if (column.translate) {
          rawData = this.translate.instant(cellData);
        } else {
          rawData = cellData;
        }
        const data = {
          cellData,
          row,
          rawData,
        };
        unordered.push(data);
      });

      /* column.order equals true means ascending */
      /* column.order equals false means descending */
      if (column.order) {
        unordered.sort(this.toAscending);
      } else {
        unordered.sort(this.toDescending);
      }

      unordered.forEach((item) => {
        orderedArr.push(item.row);
      });
      this.tableData = orderedArr;
      if (this.pagination) {
        this.setPaginatedData();
      } else {
        this.dataSource.data = this.tableData;
      }
    }
  }

  toAscending(a, b) {
    if (typeof a.rawData !== "boolean") {
      // 3rd condition added to allow empty string, to avoid different sort results among browsers
      if (a.cellData || a.cellData === 0 || typeof a.cellData === "string") {
        // Use toUpperCase() to ignore character casing
        const x =
          typeof a.rawData !== "number" && typeof a.rawData !== "boolean"
            ? a.rawData.toUpperCase()
            : a.rawData;
        const y =
          typeof b.rawData !== "number" && typeof b.rawData !== "boolean"
            ? b.rawData.toUpperCase()
            : b.rawData;
        let comparison = 0;
        if (x > y) {
          comparison = 1;
        } else if (x < y) {
          comparison = -1;
        }
        return comparison;
      }
    } else {
      const x = a.rawData;
      const y = b.rawData;
      let result = 0;
      if (x === y) {
        return result;
      } else {
        result = x ? -1 : 1;
      }
      return result;
    }
  }

  toDescending(a, b) {
    if (typeof a.rawData !== "boolean") {
      // Use toUpperCase() to ignore character casing
      // 3rd condition added to allow empty string, to avoid different sort results among browsers
      if (a.cellData || a.cellData === 0 || typeof a.cellData === "string") {
        // Use toUpperCase() to ignore character casing
        const x =
          typeof a.rawData !== "number" && typeof a.rawData !== "boolean"
            ? a.rawData.toUpperCase()
            : a.rawData;
        const y =
          typeof b.rawData !== "number" && typeof b.rawData !== "boolean"
            ? b.rawData.toUpperCase()
            : b.rawData;
        let comparison = 0;
        if (x > y) {
          comparison = 1;
        } else if (x < y) {
          comparison = -1;
        }
        return comparison * -1;
      } else {
        const x = a.rawData;
        const y = b.rawData;
        let result = 0;
        if (x === y) {
          return result;
        } else {
          result = x ? 1 : -1;
        }
        return result;
      }
    }
  }

  resetSort() {
    this.columns.forEach((item) => {
      item.sortActive = false;
    });
    this.setTableDisplay();
    this.setPaginatedData();
  }

  setTableDisplay() {
    if (this.totalLength === 0) {
      const element = document.getElementById("common-data-table");
      if (element) {
        element.style.display = "none";
      }
      const tableElement = document.getElementById("common-table-container");
      if (tableElement) {
        tableElement.classList.add("no-border");
      }
    } else {
      const element = document.getElementById("common-data-table");
      if (element) {
        element.style.display = "table";
      }
      const tableElement = document.getElementById("common-table-container");
      if (tableElement) {
        tableElement.classList.remove("no-border");
      }
    }
  }
  // New UI Revamp changes-----start
  estimateListPopOver() {
    // the trigger is given to estimate component to update the eye popover content
    this.estimateListColumnPopover.emit(this.columnHeaders);
  }
  lockColumn(columnValue) {
    let bypassFunction = true;
    const sortedColumnHeader = [];
    if (this.lockedColumnOrder.indexOf(columnValue) > -1) {
      this.lockedColumnOrder.splice(
        this.lockedColumnOrder.indexOf(columnValue),
        1
      );
    } else {
      if (this.lockedColumnOrder.length < 2) {
        bypassFunction = false;
        this.lockedColumnOrder.push(columnValue);
      }
    }
    if (!bypassFunction) {
      this.lockedColumnOrder.forEach((lockedColumn) => {
        this.columnHeaders.forEach((column) => {
          if (lockedColumn === column.value) {
            sortedColumnHeader.push(column);
          }
        });
      });
      this.columnHeaders.forEach((column) => {
        if (this.lockedColumnOrder.indexOf(column.value) > -1 === false) {
          sortedColumnHeader.push(column);
        }
      });
      this.columnHeaders = sortedColumnHeader;
      this.setDisplayedColumns();
    }
  }

  isStickyColumn(columnValue) {
    // determine whether sticky prop should be applied or not
    return this.checkForColumnIndex(columnValue);
  }

  isLockOn(columnValue) {
    // change color of lock based on whether the column is locked or not
    return this.checkForColumnIndex(columnValue);
  }

  checkForColumnIndex(columnValue) {
    if (this.lockedColumnOrder.indexOf(columnValue) > -1) {
      return true;
    } else {
      return false;
    }
  }

  isShowLock(columnValue) {
    if (this.lockedColumnOrder.length < 2) {
      return true;
    } else {
      if (this.isLockOn(columnValue)) {
        return true;
      } else {
        return false;
      }
    }
  }
  // New UI Revamp changes-----end
  ngOnDestroy() {
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  changeColumnHeaders(columns) {
    this.columnHeaders = columns;
    this.setDisplayedColumns();
  }

  updateTableData() {
    this.setTableDisplay();
  }

  rowSelect(event) {
    if (this.highlight) {
      this.selectedRow = event;
      this.selectedRowHandler.emit(this.selectedRow);
    }
  }

  onResizeEnd(event: ResizeEvent, columnName, index): void {
    this.resizeLineMargin = 0;
    this.isResizing = false;
    if (event.edges.right) {
      const cssValue = event.rectangle.width + "px";
      const columnElts = document.getElementsByClassName(
        "mat-column-" + columnName
      );
      this.columnElements(cssValue, columnElts);
      this.columns[index].width = columnElts[0].clientWidth;
      for (const key in this.prefernceData.Settings) {
        if (
          this.lockedColumnOrder.indexOf(
            this.prefernceData.Settings[key].Name
          ) > -1
        ) {
          if (this.prefernceData.Settings[key].Name === columnName) {
            const value = JSON.stringify({
              width: columnElts[0].clientWidth,
              isLock: true,
            });
            this.prefernceData.Settings[key].Value = value;
          }
        } else {
          if (this.prefernceData.Settings[key].Name === columnName) {
            const value = JSON.stringify({
              width: columnElts[0].clientWidth,
              isLock: false,
            });
            this.prefernceData.Settings[key].Value = value;
          }
        }
      }
      this.calculateMenuColumnWidth();
      this.postPrefernce();
    }
  }

  calculateMenuColumnWidth() {
    let totalColumnsWidth = 0;
    for (const key in this.columns) {
      const el = document.getElementsByClassName(
        "mat-column-" + this.columns[key].value
      );
      if (el.length) {
        totalColumnsWidth = totalColumnsWidth + el[0].clientWidth;
      }
    }
    const dataTableContainerWidth = document
      .getElementById("common-table-container")
      .getBoundingClientRect().width;

    if (dataTableContainerWidth > totalColumnsWidth + 50) {
      if (this.prefernceName == "selectedPartsheader") {
        this.calculatedMenuColumnWidth =
          dataTableContainerWidth - (totalColumnsWidth + 70);
      } else {
        this.calculatedMenuColumnWidth =
          dataTableContainerWidth - (totalColumnsWidth + 80);
      }
    } else {
      this.calculatedMenuColumnWidth = 20;
    }
    this.calculatedMenuColumnWidth = Math.abs(this.calculatedMenuColumnWidth);
  }

  onResizing(resizeEvent: ResizeEvent) {
    if (
      resizeEvent.rectangle.width > 150 &&
      resizeEvent.rectangle.right <
        document
          .getElementById("common-table-container")
          .getBoundingClientRect().width
    ) {
      if (!this.insidePopup) {
        this.resizeLineMargin = this.mousePos.clientX;
      } else {
        let widthVal = 0;
        if (this.showCheckbox) {
          const checkboxWidth = document.getElementsByClassName(
            "mat-column-checkbox"
          );
          widthVal = checkboxWidth[0].clientWidth - 45;
        }
        this.resizeLineMargin = this.mousePos.clientX - 185 - widthVal;
      }
    }
    this.isResizing = true;
  }
  sortingTables() {
    for (const i in this.columns) {
      if (this.columns[i].sortActive) {
        this.columns[i].order = !this.columns[i].order;
        this.sortTable(this.columns[i]);
        break;
      }
    }
  }
  callDisplayedColumns() {
    if (this.tableData && this.tableData.length > 0) {
      this.setDisplayedColumns();
    }
  }
  settingPreference(key, keys) {
    const valueObj = JSON.parse(this.lastprefernceData[keys].Value);
    if (valueObj?.width) {
      this.columnHeaders[key].width = Number(valueObj.width);
    } else if (valueObj) {
      this.columnHeaders[key].width = Number(valueObj);
    }
    if (valueObj && valueObj.isLock) {
      // Is column locked
      this.lockColumn(this.columnHeaders[key].value);
    }
    if (this.lastprefernceData.length === 1) {
      this.columnHeaders[key].disabled = true;
    }
  }
  columnElements(cssValue, columnElts) {
    //   for (let i = 0; i < columnElts.length; i++) {
    //     const currentEl = columnElts[i] as HTMLDivElement;
    //     currentEl.style.width = cssValue;
    //   }
    for (const currentEl of columnElts) {
      currentEl.style.width = cssValue;
    }
  }
}
