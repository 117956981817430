import { Overlay, OverlayConfig, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector, TemplatePortal } from '@angular/cdk/portal';
import { Location } from '@angular/common';
import {
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  Optional,
  TemplateRef
} from '@angular/core';
import { CcModalContainerComponent } from '@app/components/modal/modal.component.container';
import { CcModalConfig } from '@app/components/modal/modal.component.model';
import { CcModalRef } from '@app/components/modal/modal.component.ref';

/**
 * Inject data to the modal window
 */
export const CC_MODAL_DATA = new InjectionToken<any>('CcModalData');

/**
 * Inject the default modal config
 */
export const CC_MODAL_DEFAULT_CONFIG = new InjectionToken<CcModalConfig>('cc-modal-default-options');

@Injectable()
export class CcModal_ {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
    @Optional() private _location: Location,
    @Optional()
    @Inject(CC_MODAL_DEFAULT_CONFIG)
    private defaultOptions: CcModalConfig
  ) { }

  /**
   * Open modal functon for render dynamic compoent/template
   *
   * @param content <Component / Template>
   * @param config < modal config>
   */

  openModal<T, D = any, R = any>(content: ComponentType<T>, config?: CcModalConfig<D>) {
    /**
     * Merge with default config
     */
    config = { ...(this.defaultOptions || new CcModalConfig()), ...config };

    const overlayRef = this.createOverlay(config);
    if (config.type === 'modal') {
      overlayRef.hostElement.classList.add('cc-modal-overlay-wrapper');
    } else {
      overlayRef.hostElement.classList.add('cc-flagmessage-overlay-wrapper');
    }

    const modalContainer = this.attachModalContainer(overlayRef, config);
    const modalRef = this.attachModalContent<T, R>(content, modalContainer, overlayRef, config);

    return modalRef;
  }

  /**
   * create a overlay config. pass all the config to overlay
   * @param config <modal configuration from modal component>
   */
  private createOverlay(config: CcModalConfig): OverlayRef {
    let overlayConfig;
    if (config.type === 'modal') {
      overlayConfig = this.getOverlayConfig(config);
    } else {
      overlayConfig = this.getOverlayFlagConfig(config);
    }

    return this.overlay.create(overlayConfig);
  }

  /**
   * Configure all overlay config data
   *
   * @param modalConfig <modal configuration from modal component>
   */
  private getOverlayConfig(modalConfig: CcModalConfig): OverlayConfig {
    const scrollStrategy = this.createScrollStrategy(modalConfig.hasScroll);

    const state = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy,
      hasBackdrop: modalConfig.hasBackdrop,
      width: modalConfig.width,
      height: modalConfig.height,
      minWidth: modalConfig.minWidth,
      minHeight: modalConfig.minHeight,
      maxWidth: modalConfig.maxWidth,
      maxHeight: modalConfig.maxHeight,
      disposeOnNavigation: modalConfig.closeOnNavigation
    });
    if (modalConfig.backdropClass) {
      state.backdropClass = modalConfig.backdropClass;
    }
    if (modalConfig.panelClass) {
      state.panelClass = modalConfig.panelClass;
    }
    return state;
  }
  /**
   *  Configure all overlay config data for flag message
   *
   * @param modalConfig <modal configuration from modal component>
   */
  private getOverlayFlagConfig(modalConfig: CcModalConfig): OverlayConfig {
    const scrollStrategy = this.createScrollStrategy(modalConfig.hasScroll);
    const state = new OverlayConfig({
      positionStrategy: this.overlay.position().global(),
      scrollStrategy,
      disposeOnNavigation: modalConfig.closeOnNavigation
    });
    if (modalConfig.panelClass) {
      state.panelClass = modalConfig.panelClass;
    }
    return state;
  }
  /**
   * Attach the modal container to modal overlay
   * @param overlay < reference to the modal>
   * @param config <Modal configuration from the component>
   * @returns Return instance for the current modal overlay
   */
  private attachModalContainer(overlay: OverlayRef, config: CcModalConfig): CcModalContainerComponent {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    /**
     *
     */
    const injector = new PortalInjector(
      userInjector || this.injector,
      new WeakMap([[CcModalConfig, config]])
    );

    const containerPortal = new ComponentPortal(
      CcModalContainerComponent,
      config.viewContainerRef,
      injector,
      config.componentFactoryResolver
    );

    const containerRef = overlay.attach<CcModalContainerComponent>(containerPortal);

    return containerRef.instance;
  }
  /**
   * Attach the content to the modal container
   * @param content  <component/ template>
   * @param modalContainer  <Modal container>
   * @param overlayRef  <Reference to the modal>
   * @param config <Modal configuration>
   * @returns ModalRef to parent component
   */
  private attachModalContent<T, R>(
    content: ComponentType<T>,
    modalContainer: CcModalContainerComponent,
    overlayRef: OverlayRef,
    config: CcModalConfig
  ) {
    /**
     * Create modal reference
     */
    const modalRef = new CcModalRef<T, R>(overlayRef, modalContainer, this._location, config.id);
    /**
     *craete listener when hasbackdrop is true
     */
    if (config.hasBackdrop) {
      overlayRef.backdropClick().subscribe(() => {
        if (!modalRef.closeOnEsc) {
          modalRef.close();
        }
      });
    }

    /**
     *Attach the  TemplatePortal / ComponnetPortal
     */
    if (content instanceof TemplateRef) {
      modalContainer.attachTemplatePortal(
        new TemplatePortal<T>(content, null, {
          $implicit: config.data,
          modalRef
        } as any)
      );
    } else {
      const injector = this._createInjector<T>(config, modalRef, modalContainer);
      const contentRef = modalContainer.attachComponentPortal<T>(
        new ComponentPortal(content, undefined, injector)
      );
      modalRef.componentInstance = contentRef.instance;
    }
    /**update modal position */
    if (config.type !== 'modal' && !config.position) {
      config.position = {
        bottom: '50',
        right: '50'
      };
    }
    modalRef.updatePosition(config.position);

    return modalRef;
  }
  /**
   * Creates a custom injector to be used inside the modal.
   * @param config <Modal configuration>
   * @param modalRef <Modal reference>
   * @param modalContainer <Modla container element >
   * @returns The custom injector that can be used inside the dialog
   */
  private _createInjector<T>(
    config: CcModalConfig,
    modalRef: CcModalRef<T>,
    modalContainer: CcModalContainerComponent
  ): PortalInjector {
    const userInjector = config && config.viewContainerRef && config.viewContainerRef.injector;

    const injectionTokens = new WeakMap<any, any>([
      [CcModalContainerComponent, modalContainer],
      [CC_MODAL_DATA, config.data],
      [CcModalRef, modalRef]
    ]);

    return new PortalInjector(userInjector || this.injector, injectionTokens);
  }

  /**
   *@param hasScroll <hascrol from modal config>
   */
  private createScrollStrategy(hasScroll: boolean): ScrollStrategy {
    if (hasScroll) {
      return this.overlay.scrollStrategies.noop();
    } else {
      return this.overlay.scrollStrategies.block();
    }
  }
}
