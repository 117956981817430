import { TitleCasePipe, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  BrowserModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonComponentModule } from "@app/common/common.module";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { E401Component } from "@app/components/E401Component/e401.component";
import { E404Component } from "@app/components/E404Component/e404.component";
import { FooterComponent } from "@app/components/footer/footer.component";
import { CcInputFieldModule } from "@app/components/input-field/input-field.module";
import { CcModalBoxModule } from "@app/components/modal/modal.module";
import { ErrorHandlerService } from "@app/services/core/error/error-handler.service";
import { HttpInterceptService } from "@app/services/core/http/http-intercept.service";
import { SubjectService } from "@app/services/data/subject.service";
import { AddEditCustomerComponent } from "@app/settings/customer/add-edit-customer/add-edit-customer.component";
import { AddEditLaborComponent } from "@app/settings/labour/add-edit-labor/add-edit-labor.component";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { environment } from "environments/environment";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MAT_TOOLTIP_SCROLL_STRATEGY } from "@angular/material/tooltip";
import { FormatCellPipe } from "@app/common/pipes/format-cell.pipe";
import { OrderByPipe } from "@app/common/pipes/order-by.pipe";
import { PeakErrorComponent } from "@app/components/peak-error/peak-error.component";
import { ProductInfoErrorComponent } from "@app/components/product-info-error/product-info-error.component";
import { SelectCurrencyComponent } from "@app/components/select-currency/select-currency.component";
import { HomeComponent } from "@app/home/home.component";
import { PartsExpertWorkgroupComponent } from "@app/parts-expert-workgroup/parts-expert-workgroup.component";
import { PartsExpertComponent } from "@app/parts-expert/parts-expert.component";
import { UploadPricingComponent } from "@app/upload-pricing/upload-pricing.component";
import { CcCardModule } from "@cat-digital-workspace/shared-ui-core/cc-card";
import { ChartsModule } from "ng2-charts";
import { NgxDropzoneModule } from "ngx-dropzone";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DateDifferencePipe } from "@app/common/pipes/date-difference.pipe";
import { DaysLeftPipe } from "@app/common/pipes/days-left.pipe";
import { ColorPickerComponent } from "@app/components/color-picker/color-picker.component";
import { WorkgroupDeleteAlertComponent } from "@app/components/workgroup-delete-alert/workgroup-delete-alert.component";
import { ErrorNotFoundComponent } from "@app/error-not-found/error-not-found.component";
import { FooterComponentsComponent } from "@app/footer-components/footer-components.component";
import { AddEditMiscPricesComponent } from "@app/settings/misc-prices-settings/add-edit-misc-prices/add-edit-misc-prices.component";
import { MessageBar } from "@cat-digital-workspace/shared-ui-core";
import { CatButtonModule } from "@cat-digital-workspace/shared-ui-core/button";
import { CcListModule } from "@cat-digital-workspace/shared-ui-core/cc-list";
import { CcMenuModule } from "@cat-digital-workspace/shared-ui-core/contextual-menu";
import { DrawerModule } from "@cat-digital-workspace/shared-ui-core/drawer";
import { CatIconModule } from "@cat-digital-workspace/shared-ui-core/icon";
import { CcModalModule } from "@cat-digital-workspace/shared-ui-core/modal";
import { QuillModule } from "ngx-quill";
import { SkeletonLoaderComponent } from "@app/skeleton-loader/skeleton-loader.component";
import { CcLoadersModule } from "@cat-digital-workspace/shared-ui-core/loaders";
import { CcSkeletonLoaderModule,CcTooltipModule } from "@cat-digital-workspace/shared-ui-core";
import { CcTableModule } from "@cat-digital-workspace/shared-ui-core/cc-table";
import { NewUserLandingPageComponent } from "./new-user-landing-page/new-user-landing-page.component";
import { AccessRequestSubmissionComponent } from "./access-request-submission/access-request-submission.component";
import { CcDropdownModule } from "@cat-digital-workspace/shared-ui-core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

import localeEn from "@angular/common/locales/en";
import localeZh from "@angular/common/locales/zh";
import localeFr from "@angular/common/locales/fr";
import localeDe from "@angular/common/locales/de";
import localeId from "@angular/common/locales/id";
import localeIt from "@angular/common/locales/it";
import localeJa from "@angular/common/locales/ja";
import localeKo from "@angular/common/locales/ko";
import localePt from "@angular/common/locales/pt";
import localeRu from "@angular/common/locales/ru";
import localeEs from "@angular/common/locales/es";
import localeTr from "@angular/common/locales/tr";

export function createTranslateLoader(http: HttpClient) {
  // AoT requires an exported function for new factories
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function scrollFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@NgModule({
  declarations: [
    AppComponent,
    AddEditCustomerComponent,
    ConfirmAlertComponent,
    PeakErrorComponent,
    SelectCurrencyComponent,
    AddEditLaborComponent,
    FooterComponent,
    E404Component,
    E401Component,
    PeakErrorComponent,
    SelectCurrencyComponent,
    HomeComponent,
    ProductInfoErrorComponent,
    UploadPricingComponent,
    PartsExpertWorkgroupComponent,
    PartsExpertComponent,
    ColorPickerComponent,
    WorkgroupDeleteAlertComponent,
    ErrorNotFoundComponent,
    FooterComponentsComponent,
    AddEditMiscPricesComponent,
    SkeletonLoaderComponent,
    NewUserLandingPageComponent,
    AccessRequestSubmissionComponent,
  ],
  imports: [
    QuillModule.forRoot(),
    MatButtonToggleModule,
    CcListModule,
    CcMenuModule,
    DrawerModule,
    CcModalModule,
    CatButtonModule,
    CatIconModule,
    MatMenuModule,
    MatIconModule,
    ChartsModule,
    BrowserModule,
    AppRoutingModule,
    DragDropModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatTooltipModule,
    NgxDropzoneModule,
    CcLoadersModule,
    CcDropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerImmediately",
    }),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    CommonComponentModule,
    CcInputFieldModule,
    CcModalBoxModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    CcCardModule,
    CcSkeletonLoaderModule,
    CcTableModule,
    CcTooltipModule
  ],
  providers: [
    TranslateService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptService, multi: true },
    {
      provide: MAT_TOOLTIP_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
    SubjectService,
    NgbActiveModal,
    OrderByPipe,
    FormatCellPipe,
    TitleCasePipe,
    DaysLeftPipe,
    DateDifferencePipe,
    MessageBar,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Dynamically registering locale data for all languages
    registerLocaleData(localeEn);
    registerLocaleData(localeZh);
    registerLocaleData(localeFr);
    registerLocaleData(localeDe);
    registerLocaleData(localeId);
    registerLocaleData(localeIt);
    registerLocaleData(localeJa);
    registerLocaleData(localeKo);
    registerLocaleData(localePt);
    registerLocaleData(localeRu);
    registerLocaleData(localeEs);
    registerLocaleData(localeTr);
  }
}
