import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, timeout, mergeMap, take } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "environments/environment";
import { AuthService } from "@app/services/authenticate/auth.service";
import { ConfirmAlertComponent } from "@app/components/confirm-alert/confirm-alert.component";
import { DataService } from "@app/services/shared/storage/data.service";
import * as ServiceMappings from "@app/services/shared/app-constants";
import { WorkgroupDeleteAlertComponent } from "@app/components/workgroup-delete-alert/workgroup-delete-alert.component";
import { Router } from "@angular/router";
import { WGRole } from "@app/services/models/roles";
import { UserService } from "@app/services/misc/user.service";
import { MatDialog } from "@angular/material/dialog";
import { CONTENT_CONTEXT_TOKEN } from "@app/services/rest/rest.service";
import { SubjectService } from "@app/services/data/subject.service";
import { AppInsightsService } from "@app/services/misc/App-Insights/app-insights.service";

const platform = require("platform");
const info = platform.parse(platform.ua);
@Injectable({
  providedIn: "root",
})
export class HttpInterceptService implements HttpInterceptor {
  workGroupDdlData = [];
  activeclass: any;
  content = "";
  private forBiddenAlertPresented = false;
  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    public modalService: NgbModal,
    private dataService: DataService,
    private router: Router,
    private userService: UserService,
    public matDialog: MatDialog,
    public subjectService: SubjectService,
    private appInsight: AppInsightsService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = this.addAuthHeader(req);
    return next.handle(req).pipe(
      timeout(300000),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (
            this.authService.isAuthenticated() ||
            this.authService.isAuthenticating
          ) {
            return this.authService
              .getRefreshTokenFromAuthorizationServer()
              .pipe(
                mergeMap(() => {
                  req = this.addAuthHeader(req);
                  return next.handle(req);
                }),
                catchError((err) => {
                  this.showSessionExpiredAlert();
                  return throwError(err);
                })
              );
          } else if (!this.authService.isAuthenticated()) {
            this.showSessionExpiredAlert();
          }
        } else if (error.status === 403) {
          // Forbidden the access is blocked
          this.showForBiddenAlert(error);
          return throwError(error);
        } else if (
          error.status === 404 &&
          error.error.MessageKey == "DeletedWorkGroup"
        ) {
          this.checkForActiveClass(error);
        } else {
          this.handleError(error);
        }
        return throwError(error);
      })
    );
  }

  checkForActiveClass(error: HttpErrorResponse) {
    if (!this.activeclass) {
      if (this.matDialog) {
        this.matDialog.closeAll();
      }
      if (this.modalService) {
        this.modalService.dismissAll();
      }
      this.handleDeleteWorkgroupAlert(error);
    }
  }

  private addAuthHeader(request: any) {
    const token = !environment.devModeEnabled
      ? this.authService.getAccessToken()
      : environment.tempAccessToken;
    let acceptLanguage = "en-US";
    if (localStorage.getItem("countryCode") != null) {
      acceptLanguage = localStorage.getItem("countryCode");
    }
    if (
      token &&
      request.url.indexOf("assets/i18n") === -1 &&
      request.url.indexOf(ServiceMappings.accessTokenUrl) === -1
    ) {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = request.clone({
        headers: request.headers
          .set("Authorization", "Bearer " + token)
          .set("Client", "AdvancedCalculator")
          .set("Client_Version", environment.AppVersion)
          .set("Device_Model", info.os.toString())
          .set("Device_Platform", info.name)
          .set("Device_Version", info.version)
          .set("Access-Control-Allow-Origin", "*")
          .set("accept-language", acceptLanguage),
      });
      request = this.addtionalAuthHeaders(request);
      return request;
    } else {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      return request.clone({ headers: request.headers });
    }
  }

  addtionalAuthHeaders(request) {
    if (
      request.url.includes(ServiceMappings.workgroupList) ||
      request.url.includes(ServiceMappings.setOrgRole)
    ) {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForOrgDetails(request);
      return request;
    } else if (request.url.includes(ServiceMappings.copyWorkGroup)) {
      const targetWorkgroupId = request.url.substring(
        request.url.indexOf("=") + 1,
        request.url.indexOf("&")
      );
      const tempTarget = request.url.substring(request.url.indexOf("=") + 1);
      const target = tempTarget.substring(tempTarget.indexOf("=") + 1);
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForCopyWG(targetWorkgroupId, request, target);
      return request;
    } else if (
      request.url.indexOf(ServiceMappings.mpeUploadAndSummarizeBuilderXML) !==
        -1 ||
      request.url.indexOf(ServiceMappings.builderXml) !== -1 ||
      request.url.indexOf(ServiceMappings.uploadEngineperformance) !== -1 ||
      request.url.indexOf(ServiceMappings.priceFileUpload) !== -1 ||
      request.url.indexOf(ServiceMappings.addCustomerlist) !== -1 ||
      request.url.indexOf(ServiceMappings.uploadPartsPicingFile) !== -1
    ) {
      // Do not Send Content-Type for UploadBuilderXML API
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForUploadAPIs(request);
      return request;
    } else if (request.url.includes(ServiceMappings.logoutUrl)) {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForLogout(request);
      return request;
    } else {
      let organizationId = "";
      let workgroupId = "";
      let orgType = "";
      if (this.dataService.defaultOrg) {
        organizationId = this.dataService.defaultOrg.Organization.Id;
        orgType = this.dataService.defaultOrg.Organization.Type;
        workgroupId = this.dataService.targetWGId
          ? this.dataService.targetWGId
          : this.dataService.defaultOrgWG.WorkGroupId;
      }
      if (this.dataService.isQe || this.dataService.isAdmin) {
        workgroupId = "";
      }
      this.setContent(request);
      return request.clone({
        headers: request.headers
          .set("Accept", this.content)
          .set("Content-Type", "application/json")
          .set("OrganizationId", organizationId)
          .set("OrganizationType", orgType)
          .set("WorkgroupId", workgroupId),
      });
    }
  }

  private authHeaderForLogout(request: any) {
    this.setContent(request);
    request = request.clone({
      headers: request.headers.set("Accept", this.content),
    });
    return request.clone({
      headers: request.headers.set(
        "Refresh-Token",
        localStorage.getItem("RefreshToken")
      ),
    });
  }

  private authHeaderForOrgDetails(request: any) {
    if (request.url.includes(ServiceMappings.workgroupList)) {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForWorkGroupListAPI(request);
      return request;
    } else if (request.url.includes(ServiceMappings.setOrgRole)) {
      this.setContent(request);
      request = request.clone({
        headers: request.headers.set("Accept", this.content),
      });
      request = this.authHeaderForSaveOrganizationAffiliationAPI(request);
      return request;
    }
  }
  private authHeaderForWorkGroupListAPI(request: any) {
    const organizationId = request.url.substring(
      request.url.indexOf("=") + 1,
      request.url.indexOf("&")
    );
    const urlSubString = request.url.substring(request.url.indexOf("=") + 1);
    const orgType = urlSubString.substring(urlSubString.indexOf("=") + 1);
    request.url = ServiceMappings.workgroupList;
    request.urlWithParams = ServiceMappings.workgroupList;
    this.setContent(request);
    return request.clone({
      headers: request.headers
        .set("Accept", this.content)
        .set("Content-Type", "application/json")
        .set("OrganizationId", organizationId)
        .set("OrganizationType", orgType)
        .set("WorkgroupId", ""),
    });
  }

  private authHeaderForSaveOrganizationAffiliationAPI(request: any) {
    const organizationId = request.url.substring(
      request.url.indexOf("=") + 1,
      request.url.indexOf("&")
    );
    let urlSubString = request.url.substring(request.url.indexOf("=") + 1);
    urlSubString = urlSubString.substring(urlSubString.indexOf("=") + 1);
    const orgType = urlSubString.substring(
      urlSubString.indexOf(""),
      urlSubString.indexOf("&")
    );
    urlSubString = urlSubString.substring(urlSubString.indexOf("="));
    const CWSID = urlSubString.substring(
      urlSubString.indexOf("=") + 1,
      urlSubString.indexOf("&")
    );
    urlSubString = urlSubString.substring(urlSubString.indexOf("=") + 1);
    const CatRecId = urlSubString.substring(urlSubString.indexOf("=") + 1);
    request.url =
      ServiceMappings.setOrgRole + "?CWSID=" + CWSID + "&CatRecId=" + CatRecId;
    request.urlWithParams =
      ServiceMappings.setOrgRole + "?CWSID=" + CWSID + "&CatRecId=" + CatRecId;
    let workgroupId = "";
    if (this.dataService.defaultOrg) {
      workgroupId = this.dataService.defaultOrgWG.WorkGroupId;
    }
    this.setContent(request);
    return request.clone({
      headers: request.headers
        .set("Accept", this.content)
        .set("Content-Type", "application/json")
        .set("OrganizationId", organizationId)
        .set("OrganizationType", orgType)
        .set("WorkgroupId", workgroupId),
    });
  }
  private authHeaderForCopyWG(
    targetWorkgroupId: any,
    request: any,
    target: any
  ) {
    if (target === "wg") {
      request.url = ServiceMappings.saveWorkGroup;
      request.urlWithParams = ServiceMappings.saveWorkGroup;
    } else if (target === "parts") {
      request.url = ServiceMappings.savePartPrice;
      request.urlWithParams = ServiceMappings.savePartPrice;
    } else if (target === "customers") {
      request.url = ServiceMappings.saveCustomer;
      request.urlWithParams = ServiceMappings.saveCustomer;
    }
    let organizationId = "";
    let orgType = "";
    if (this.dataService.defaultOrg) {
      organizationId = this.dataService.defaultOrg.Organization.Id;
      orgType = this.dataService.defaultOrg.Organization.Type;
    }
    this.setContent(request);
    return request.clone({
      headers: request.headers
        .set("Accept", this.content)
        .set("Content-Type", "application/json")
        .set("OrganizationId", organizationId)
        .set("OrganizationType", orgType)
        .set("WorkgroupId", targetWorkgroupId),
    });
  }
  private authHeaderForUploadAPIs(request: any) {
    let organizationId = "";
    let workgroupId = "";
    let orgType = "";
    if (this.dataService.defaultOrg) {
      organizationId = this.dataService.defaultOrg.Organization.Id;
      orgType = this.dataService.defaultOrg.Organization.Type;
      workgroupId = this.dataService.defaultOrgWG.WorkGroupId;
    }
    this.setContent(request);
    return request.clone({
      headers: request.headers
        .set("Accept", this.content)
        .set("OrganizationId", organizationId)
        .set("OrganizationType", orgType)
        .set("WorkgroupId", workgroupId),
    });
  }
  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  showForBiddenAlert(error: HttpErrorResponse) {
    if (this.forBiddenAlertPresented === false) {
      this.forBiddenAlertPresented = true;
      const message = this.translate.instant("forbiddenmsg");
      const modalRef = this.modalService.open(ConfirmAlertComponent, {
        centered: true,
        windowClass: "confirm-modal",
        backdrop: "static",
        keyboard: false,
      });
      modalRef.componentInstance.notAuthorized = true;
      modalRef.componentInstance.noCloseIcon = true;
      modalRef.componentInstance.alertMessage = message;
      modalRef.componentInstance.buttons = [
        {
          text: this.translate.instant("confirmOk"),
          cssClass: ["cat-btn-primary"],
          handler: (modal) => {
            this.authService.removeAuthToken();
            this.authService.logout();
            modal.close(false);
          },
        },
      ];
      setTimeout(() => {
        this.forBiddenAlertPresented = false;
      }, 30000);
    }
  }

  handleDeleteWorkgroupAlert(error) {
    this.filterWorkGroupDropDown();
    if (this.workGroupDdlData.length > 0) {
      this.showWorkgroupDeletedAlert(error);
    } else {
      this.showWorkgroupDeleteAlertNoWg(error);
    }
  }

  filterWorkGroupDropDown() {
    const tempArray = this.dataService.workgroups;
    this.workGroupDdlData = tempArray.filter((el) => {
      return (
        (el.Role === WGRole.Admin ||
          el.Role === WGRole.Advanced ||
          el.Role === WGRole.ReadOnly) &&
        el.WorkGroupId != this.dataService.defaultOrgWG.WorkGroupId
      );
    });
  }

  showWorkgroupDeletedAlert(error: HttpErrorResponse) {
    const modalRef = (this.activeclass = this.modalService.open(
      WorkgroupDeleteAlertComponent,
      {
        centered: true,
        windowClass: "labor-popup",
        backdrop: "static",
        keyboard: false,
      }
    ));
    modalRef.componentInstance.alertMessage = error.error.Message;
    modalRef.componentInstance.workGroupList = this.workGroupDdlData;
    modalRef.result.then((data) => {
      const request = {
        Name: "DefaultWG",
        Settings: [
          {
            Name: "DefaultWG",
            Value: this.dataService.defaultOrgWG.WorkGroupId,
          },
        ],
      };
      this.userService.saveUserPreferences(request).pipe(take(1)).subscribe();
      this.router.navigate(["/home"], { state: { action: "reload" } });
    });
  }

  showWorkgroupDeleteAlertNoWg(error) {
    const message = this.translate.instant("partsExpertNoWorkGroupMsg");
    const modalRef = this.modalService.open(ConfirmAlertComponent, {
      centered: true,
      windowClass: "confirm-modal",
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.isMessage = true;
    modalRef.componentInstance.noCloseIcon = true;
    modalRef.componentInstance.alertMessage = message;
    modalRef.componentInstance.buttons = [
      {
        text: this.translate.instant("confirmOk"),
        cssClass: ["cat-btn-primary"],
        handler: (modal) => {
          modal.close(false);
          this.dataService.defaultOrgWG = {
            Role: "",
            WorkGroupId: "",
            WorkGroupName: "",
          };
          this.dataService.defaultWGRole = "ReadOnly";
          this.subjectService.sendMessage("ReadOnly", "defaultWGRoleChanged");
          this.dataService.canAccessCalc = false;
          this.router.navigateByUrl("/home");
        },
      },
    ];
  }

  showSessionExpiredAlert() {
    this.authService.removeAuthToken();
    this.authService.initLogin();
  }

  /**
   * This method is used to set Accept header for HTTP requests and the
   * default value of CONTENT_CONTEXT_TOKEN is application/json
   */
  setContent(request: HttpRequest<any>) {
    this.content = request.context.get(CONTENT_CONTEXT_TOKEN);
  }
}
