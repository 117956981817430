import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from "@angular/material/tooltip";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColorPickerComponent } from "@app/components/color-picker/color-picker.component";
import { QuickEstimateServiceNew } from "@app/quick-estimator-new/quick-estimator-new-services/quick-estimate-data-share.service";
import { SubjectService } from "@app/services/data/subject.service";
import { EstimatePricingService } from "@app/services/estimate-pricing/estimate-pricing.service";
import { UserService } from "@app/services/misc/user.service";
import { EstimateType } from "@app/services/models/misc/general";
import { SysRole, WGRole } from "@app/services/models/roles";
import * as constants from "@app/services/shared/app-constants";
import { DataService } from "@app/services/shared/storage/data.service";
import {
  CcDrawerPosition,
  DrawerService,
} from "@cat-digital-workspace/shared-ui-core/drawer";
import { CcModal } from "@cat-digital-workspace/shared-ui-core/modal";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { filter } from "rxjs/operators";
import ReleaseJson from "../../assets/release-notes.json";
import featuresJson from "../../assets/upcoming-features.json";
import { environment } from "environments/environment";
import { MultipleProductService } from "@app/services/multiple-product/multiple-product.service";
import { ProgressIndicatorComponent } from "@app/common/progress-indicator/progress-indicator.component";

interface loadingDataI {
  Content: [
    { Name: string; Value: string },
    { Name: string; Value: string },
    { Name: string; Value: string },
    { Name: string; Value: string },
    { Name: string; Value: string }
  ];
  Name: string;
}

export const TooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 200,
  hideDelay: 0,
  touchendHideDelay: 1000,
};

const MAX_LENGTH = 613;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: TooltipDefaults },
  ],
})
export class HomeComponent implements OnInit {
  readonly: boolean;
  constructor(
    public sanitizer: DomSanitizer,
    private _modal: CcModal,
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public dataService: DataService,
    public router: Router,
    private quickEstimateService: QuickEstimateServiceNew,
    public estimatePricingService: EstimatePricingService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private subjectService: SubjectService,
    private drawerService: DrawerService,
    public snackBar: MatSnackBar,
    private modalService: NgbModal,
    private mpeService: MultipleProductService
  ) {
    this.releaseNoteIcon = "../../assets/images/release-notes.png";
  }
  @ViewChild(ColorPickerComponent)
  private colorpicker: ColorPickerComponent;
  blue = "blue";
  creationDate: any = "";
  endUsage: boolean;
  startUsage: boolean;
  estimateName: boolean;
  quickEstimteForm: FormGroup;
  private estimateSubscribe: any;
  eoptions = [
    { value: "Standard Estimate", route: "/estimate/estimateDetails" },
    {
      value: "Multiple Product Estimate",
      route: "/estimate/MultiestimateDetails",
    },
  ];
  estimateList: any = [];
  displayedColumns: any = [];
  tableColumns = [];
  isEditAllowed: any = false;
  showFetch = true;
  showLoad = true;
  isAdmin = SysRole.Admin;
  base64textString: String = "";
  binaryString: any;
  imagePath: any;
  addNewCardData = "";
  noContent = false;
  upload: boolean;
  sizelimit = false;
  isArchived = false;
  editcards: any;
  alert = false;
  filename: string;
  show = true;
  items = 3;
  @Input() files: File[] = [];
  multipleFilesUploaded = false;
  @Input() sourceDataToDisplay;
  engine = EstimateType.Engine;
  machine = EstimateType.Machine;
  equipType = this.machine;
  DOMString: any;
  inputdata: any;
  cardNavLink = "";
  cardOption = "";
  cardColor = "";
  isMpe = false;
  newCardPlaceholder = "Add description here";
  drawerSizes = [
    { value: "SMALL", label: "SMALL" },
    { value: "MEDIUM", label: "MEDIUM" },
    { value: "LARGE", label: "LARGE" },
  ];
  drawerSize = this.drawerSizes[0].value;
  columns = [
    {
      label: "name",
      value: "name",
      show: true,
      cssClass: "col-name",
      width: 150,
      keyPath: "PlanName",
      searchEnabled: true,
      isLock: false,
    },
    {
      label: "model",
      value: "model",
      show: true,
      keyPath: "Model",
      cssClass: "col-model",
      width: 75,
      searchEnabled: true,
      isLock: false,
    },
    {
      label: "serialPrefix",
      value: "serialNoPrefix",
      keyPath: "Prefix",
      show: true,
      cssClass: "col-serialPrefix",
      width: 100,
      searchEnabled: true,
      isLock: false,
    },

    {
      label: "lastUpdatedBy",
      value: "lastUpdatedBy",
      keyPath: "LastModifiedBy",
      show: true,
      cssClass: "col-lastUpdatedBy",
      width: 100,
      searchEnabled: true,
      isLock: false,
    },
    {
      label: "lastUpdatedDate",
      value: "lastUpdatedDate",
      keyPath: "LastModifiedOn",
      show: true,
      cssClass: "col-lastUpdatedDate",
      width: 100,
      searchEnabled: true,
      date: true,
      isLock: false,
    },
    {
      label: "status",
      value: "status",
      keyPath: "Status",
      show: true,
      conditionalCss: true,
      cssClass: "col-status",
      width: 100,
      searchEnabled: true,
      isLock: false,
    },
  ];
  valueInput = false;
  features = featuresJson.UpcomingFeatures;
  releaseNotes = ReleaseJson.ReleaseNotes;
  releaseNoteIcon;

  loadUrl: any;
  private routerSub: any;
  private userSub: any;
  hover: any = true;
  editToolTip: any = this.translate.instant("edit-tooltip");
  releaseNotesShowed = [];
  rowData;
  trainingResourceCardsData: loadingDataI[] = [];
  selectedCardIndex = -1;

  public overlayposition = [
    { label: "Auto", value: undefined },
    { label: "Bottom", value: "bottom" },
    { label: "Top", value: "top" },
    { label: "Left", value: "left" },
    { label: "Right", value: "right" },
    { label: "Top Center", value: "topCenter" },
    { label: "Top Right", value: "topright" },
    { label: "Top Left", value: "topleft" },
    { label: "Bottom Center", value: "bottomCenter" },
    { label: "Bottom right", value: "bottomright" },
    { label: "Bottom Left", value: "bottomleft" },
  ];
  public topPlacementPosition = [{ ...this.overlayposition[0] }];
  public rightPlacementPosition = [{ ...this.overlayposition[0] }];
  public bottomPlacementPosition = [{ ...this.overlayposition[0] }];
  public leftPlacementPosition = [{ ...this.overlayposition[0] }];
  public centerPlacementPosition = [{ ...this.overlayposition[0] }];

  options = [
    {
      option: "Edit",
    },
    {
      option: "Delete",
    },
  ];
  trainingResourcesDrawer;
  colordata: any;
  loaddata: any;
  trainingCardSelected: string;
  imageValue: string;
  loadingdata: unknown[];
  loadspinner = true;
  trainingCardImage: string;
  id: string;
  backupimage: string;
  backupbasestring: string;
  linkValidationMessage = "";
  navLinkMsg = false;
  navPattern = constants.urlPatternRegex;
  estimateData: [];
  cardLoader = true;
  private estimateEnumSubscribe: any;

  modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  tableConfig = {
    stickyHeader: false,
    expandable: false,
    isBulkAction: false,
    isSortEnable: false,
    isBulkActionOpenOnSingleClick: false,
    isBulkActionOverlayClose: false,

    columnConfig: [
      {
        name: "PlanName",
        title: "Name",
        width: 50,
        component: null,
        align: "left",
        priority: 2,
      },
      {
        name: "Model",
        title: "Model",
        width: 30,
        component: null,
        align: "left",
        priority: 2,
      },
      {
        name: "Prefix",
        title: "Serial # Prefix",
        width: 55,
        component: null,
        align: "center",
        priority: 2,
      },
      {
        name: "LastModifiedBy",
        title: "Last Updated By",
        width: 75,
        component: null,
        align: "center",
        priority: 2,
      },
      {
        name: "LastModifiedOn",
        title: "Last Updated Date",
        width: 100,
        component: null,
        align: "center",
        priority: 2,
      },
      {
        name: "Status",
        title: "Status",
        width: 50,
        component: null,
        align: "center",
        priority: 2,
      },
    ],
  };

  startValue = "";
  endValue = "";
  validForms = [];
  estimateValue = "";
  newUsercheckFlag = false;
  estimateTypeTab = "nav-standard-tab";
  estimateListBackUp = [];
  multiProdEstimateListBackUp = [];
  mpeDefinitionType = "MultiProduct";
  wgUom: string;

  ngOnInit() {
    this.subscribeToUomChanges();
    this.subscribeToWgChanges();
    this.cardLoader = true;
    this.dataService.showLoading = true;
    if (
      !this.estimatePricingService.planSelectionValues &&
      this.dataService.canAccessCalc
    ) {
      this.getPlanSelectionValues();
    }
    const val = this.subjectService.getEngorMach();
    if (val) {
      this.equipType = val;
    }
    const releaseNote = this.releaseNotes.filter((elem) =>
      elem.version.includes("22.0.0")
    );
    this.releaseNotesShowed = releaseNote;
    this.formData();

    this.creationDate = new Date();
    if (this.dataService.systemRole) {
      this.fetchEstimates(false);
      this.loadtrainingcard();
    } else {
      this.userSub = this.userService.isProfileLoaded.subscribe((res) => {
        this.fetchEstimates(false);
        this.loadtrainingcard();
      });
    }
    this.routerSub = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Reload the API request only if the WG dropdown is changed
        if (
          this.router.getCurrentNavigation().extras &&
          this.router.getCurrentNavigation().extras.state
        ) {
          if (
            this.router.getCurrentNavigation().extras.state.action === "reload"
          ) {
            this.fetchEstimates(false);
          }
        }
      });
    this.setDisplayedColumns();
    this.checkForEditIcon();
    this.subjectService.defaultWGRoleListener$.subscribe((data) => {
      this.checkForEditIcon();
    });
    this.loadUrl = this.cardUrlJson();
    this.subjectService.setmpeFlag(true);
    this.mpeService.initialBasicDataEntered = null;
    this.mpeService.basicCustDetails = null;
    this.mpeService.sellValues = null;
    this.mpeService.costValues = null;
  }

  subscribeToUomChanges() {
    this.dataService.uomUpdated$.subscribe((updated) => {
      if (updated) {
        this.wgUom = this.dataService.getUom();
      }
    });
  }

  subscribeToWgChanges() {
    this.dataService.wgChanged$.subscribe((updated) => {
      if (updated) {
        this.wgUom = this.dataService.getUom();
      }
    });
  }

  checkForEditIcon() {
    if (this.dataService.defaultWGRole !== WGRole.ReadOnly) {
      this.isEditAllowed = true;
      this.readonly = false;
      const index = this.displayedColumns.indexOf("edit");
      if (index < 0) {
        this.displayedColumns.push("edit");
      }
    } else {
      this.readonly = true;
      const index = this.displayedColumns.indexOf("edit");
      if (index >= 0) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }
  formData() {
    this.endUsage = false;
    this.startUsage = false;
    this.estimateName = false;
    this.quickEstimteForm = this.formBuilder.group({
      estimateName: [""],
      startUsage: [""],
      endUsage: [""],
    });
  }

  openModalWithTemplate(content: any) {
    this._modal.openModal(content, {
      type: "semi-modal",
      width: "537px",
      data: { name: "test" },
      panelClass: "atom-pg-panelClass",
      backdropClass: "overlay-for-filter",
      isAutoHeightModalContent: true,
      hasScroll: true,
      closeOnEsc: false,
      disableBackdropClick: true,
    });
  }

  checkData(): boolean {
    if (
      this.imageValue == "" ||
      this.inputdata == "" ||
      this.addNewCardData == "" ||
      this.colordata == "" ||
      this.addNewCardData == null ||
      this.cardNavLink == ""
    ) {
      return true;
    }

    if (!(this.linkValidationMessage === "")) {
      return true;
    }

    if (this.trainingCardSelected == "addNewCard") {
      return false;
    }

    if (this.selectedCardIndex == -1) {
      return false;
    }

    if (
      this.trainingResourceCardsData[this.selectedCardIndex].Content[0].Value ==
        this.inputdata &&
      this.trainingResourceCardsData[this.selectedCardIndex].Content[1].Value ==
        this.addNewCardData &&
      this.trainingResourceCardsData[this.selectedCardIndex].Content[2].Value ==
        this.base64textString &&
      this.trainingResourceCardsData[this.selectedCardIndex].Content[3].Value ==
        this.colordata &&
      this.trainingResourceCardsData[this.selectedCardIndex].Content[4].Value ==
        this.cardNavLink
    ) {
      return true;
    }

    return false;
  }

  openEmptyDrawer(content?: any) {
    this.setBodyOverflow();
    this.trainingResourcesDrawer = this.drawerService.openDrawer(content, {
      backdropClass: "overlay-for-filter",
      hasScroll: true,
      id: "listDrawer",
      position: CcDrawerPosition.RIGHT,
      closeOnEsc: false,
      disableBackdropClick: true,
    });
    this.trainingResourcesDrawer.afterClosed().subscribe(() => {
      this.removeBodyScroll();
    });
    this.trainingResourcesDrawer.onClose.subscribe((_) => {
      this.trainingResourcesDrawer.disableBackdropClick = true;
    });
  }

  apply() {
    if (this.cardOption == "Create") {
      const crypto = window.crypto;
      const array = new Uint32Array(1);
      this.id =
        this.inputdata +
        "_" +
        crypto.getRandomValues(array).toString().substr(2, 9);
    }

    const data = {
      Name: this.id,
      Content: [
        {
          Name: "Title",
          Value: this.inputdata,
        },
        {
          Name: "Description",
          Value: this.addNewCardData,
        },
        {
          Name: "Image",
          Value: this.base64textString,
        },
        {
          Name: "Attributes",
          Value: this.colordata,
        },
        {
          Name: "Navigation",
          Value: this.cardNavLink,
        },
      ],
    };

    this.saveApi(data, false, true);

    const jsondata = {
      Name: this.id,
      Value: "Title1, 1, etc",
    };

    if (this.cardOption == "Save") {
      this.loaddata.Content.forEach((element, index, array) => {
        if (element.Name == this.id) {
          array.splice(index, 1);
        }
      });
    }

    this.loaddata.Content.push(jsondata);
    this.saveApi(this.loaddata);
  }

  delete() {
    const data = {
      Name: this.id,
      Content: [],
    };

    this.loaddata.Content.forEach((element, index, array) => {
      if (element.Name == this.id) {
        array.splice(index, 1);
      }
    });

    this.saveApi(this.loaddata, true);
    this.saveApi(data, true, true);
  }

  saveApi(datas, isfromdelete?, issave?) {
    this.loadspinner = true;
    this.estimatePricingService.saveTrainingData(datas).subscribe(
      (data) => {
        if (this.trainingResourcesDrawer) {
          this.trainingResourcesDrawer.close();
        }
        if (!isfromdelete && issave) {
          if (this.trainingResourcesDrawer) {
            this.trainingResourcesDrawer.close();
          }
          this.snackBar.open(
            this.translate.instant("trainingResourceMsg"),
            "",
            {
              duration: 4000,
              panelClass: ["status-snack-bar"],
            }
          );
          this.loadtrainingcard();
        } else if (isfromdelete && issave) {
          this.snackBar.open(
            this.translate.instant("trainingResourcesMsgDelete"),
            "",
            {
              duration: 4000,
              panelClass: ["status-snack-bar"],
            }
          );
          this.loadtrainingcard();
        }
        this.loadspinner = false;
        this.resetall();
      },
      (err) => {
        this.snackBar.open(this.translate.instant("serverErr"), "", {
          duration: 4000,
          panelClass: ["status-snack-bar"],
        });
        if (err.status == 404 && err.error.MessageKey == "DeletedWorkGroup") {
          this.closeModal();
        }
        this.loadspinner = false;
        this.resetall();
        return;
      }
    );
  }

  addNewClicked() {
    this.cardOption = "Create";
    this.editcards = "newCard";
    this.inputdata = "";
    this.cardNavLink = "";
    this.colordata = "";
    this.cardColor = "";
    this.imageValue = "";
  }

  editExistingCard() {
    this.cardOption = "Save";
    this.checkNav();
    this.setBodyOverflow();
  }

  showEditBox(cardSelected: string) {
    this.trainingCardSelected = cardSelected;
    this.trainingCardImage = cardSelected;

    if (cardSelected != "addNewCard") {
      for (let i = 0; i < this.trainingResourceCardsData.length; i++) {
        if (this.trainingResourceCardsData[i].Name == cardSelected) {
          this.selectedCardIndex = i;
          this.addNewCardData =
            this.trainingResourceCardsData[i].Content[1].Value;
          this.colordata = this.trainingResourceCardsData[i].Content[3].Value;
          this.cardColor = this.trainingResourceCardsData[i].Content[3].Value;
          this.inputdata = this.trainingResourceCardsData[i].Content[0].Value;
          this.backupimage = this.trainingResourceCardsData[i].Content[2].Value;
          this.imageValue = this.trainingResourceCardsData[i].Content[2].Value;
          this.cardNavLink = this.trainingResourceCardsData[i].Content[4].Value;
          this.backupbasestring =
            this.trainingResourceCardsData[i].Content[2].Value;
          this.base64textString =
            this.trainingResourceCardsData[i].Content[2].Value;
          this.id = this.trainingResourceCardsData[i].Name;
          break;
        }
      }
    } else {
      this.addNewCardData = "";
      this.cardColor = "";
      this.inputdata = "";
      this.cardNavLink = "";
    }
  }

  // onEstimateNameBlur() {
  //   this.estimateName = true;
  // }

  textChanged($event) {
    if ($event.editor.getLength() > MAX_LENGTH) {
      $event.editor.deleteText(MAX_LENGTH, $event.editor.getLength());
      this.addNewCardData = $event.editor.root.innerHTML;
    }
  }

  closeModal() {
    this.trainingResourcesDrawer.close();
    this.files = [];
    this.filename = "";
    this.show = true;
    this.alert = false;
    this.multipleFilesUploaded = false;
    this.imagePath = "";
    this.imageValue = "";
    this.cardNavLink = "";
    this.addNewCardData = "";
    this.sizelimit = false;
  }

  navigateTo() {
    let value = "";

    const formValidObj = {
      formName: "Quick Estimate Form - Home Screen",
      formContent: "User Entered Value",
      formContent2: null,
      formStatus: "success",
    };

    if (this.valueInput) {
      Object.keys(this.quickEstimteForm.value).forEach((key, index, array) => {
        if (
          this.quickEstimteForm.value[key] !== null &&
          this.quickEstimteForm.value[key] !== ""
        ) {
          value =
            index < array.length - 1 &&
            this.quickEstimteForm.value[array[index + 1]] !== ""
              ? value + this.quickEstimteForm.value[key] + " | "
              : value + this.quickEstimteForm.value[key];
          formValidObj.formContent = this.quickEstimteForm.value[key];
        }
      });
      if (value.split("|").length > 1) {
        formValidObj.formContent2 = value;
      }
      this.quickEstimateService.continueClicked(this.quickEstimteForm.value);
      this.subjectService.setFormChangesStream({
        isFormSubmitted: true,
        formValue: formValidObj,
      });
    } else {
      this.quickEstimateService.continueClicked("");
    }

    this.router.navigateByUrl("/quick-estimator");
  }

  createEstimate() {
    this.router.navigate(["/estimate/estimateDetails"]);
    this.subjectService.setmpeFlag(true);
    this.subjectService.setSOflag(false);
  }
  createSOEstimate() {
    this.router.navigate(["/estimate/estimateDetails"]);
    this.subjectService.setSOflag(true);
  }

  createMPEstimate() {
    this.mpeService.showmorePricingFlag(true);
    this.router.navigate(["/MPestimate/estimateDetails"]);
    this.subjectService.setmpeFlag(false);
  }
  seeAllEstimates() {
    this.router.navigateByUrl("/estimate");
  }

  enableNextButton() {
    if (this.quickEstimteForm && this.quickEstimteForm.valid) {
      return false;
    } else {
      return true;
    }
  }
  sortByLastModifiedOn(array) {
    return array.sort((a, b) => {
      a = new Date(a.LastModifiedOn);
      b = new Date(b.LastModifiedOn);
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  setDisplayedColumns() {
    this.columns.forEach((column, index) => {
      this.displayedColumns.push(column.value);
    });
  }

  cssStyling(row, column) {
    if (column.value === "status") {
      if (row.Status === "Draft") {
        return "text-uppercase status-badge-draft";
      } else if (row.Status === "Active") {
        return "text-uppercase status-badge-active";
      } else if (row.Status === "Finalized") {
        return "text-uppercase status-badge-finalized";
      } else {
        return "text-uppercase";
      }
    }
  }

  editData(row) {
    if (!this.isMpe) {
      this.estimatePricingService.currencySelected = row.CurrencyType;
      localStorage.setItem(
        "estimateCurrency",
        this.estimatePricingService.currencySelected
      );
      this.router.navigate([
        "/estimate/estimateDetails",
        { planNumber: row.PlanNumber, action: "edit" },
      ]);
    } else {
      this.router.navigate([
        "/MPestimate/estimateDetails",
        { planNumber: row.PlanNumber, action: "edit" },
      ]);
    }
  }

  checkWgUom(row, templateRef) {
    if (this.wgUom !== row.SystemOfMeasurement) {
      this.rowData = row;
      this.openModalWithTemplate(templateRef);
    } else {
      this.editData(row);
    }
  }

  onProceed() {
    this.callEstimateAndApply(this.rowData.PlanNumber);
    //this.editData(this.rowData,true)
  }

  callEstimateAndApply(planNumber) {
    this._modal.close();
    const modalRef = this.modalService.open(ProgressIndicatorComponent, {
      centered: true,
      backdrop: "static",
      windowClass: "progress-spinner-modal",
      keyboard: false,
    });
    modalRef.componentInstance.message = this.translate.instant("waitMsg");
    const estUomUpdatePayload = [
      {
        SystemOfMeasurement: this.wgUom,
      },
    ];
    if(!this.isMpe){
    this.estimatePricingService
      .editEstimateAndApply(planNumber, estUomUpdatePayload)
      .subscribe((response) => {
        if (
          response &&
          response.CheckoutResult &&
          response.CheckoutResult.Success
        ) {
          if (response.OverallStatus === "Success") {
            modalRef.close();
            this.editData(this.rowData);
          } else if (response.OverallStatus === "Failure") {
            modalRef.close();
            this.snackBar.open(this.translate.instant("unknownerr"), "", {
              duration: 4000,
              panelClass: ["status-snack-bar"],
            });
          }
        }
      },
      (err)=>{
        modalRef.close();
        if (err.status === 0 || err.status == 504) {
          this.snackBar.open(this.translate.instant("networkError"), "", {
            duration: 4000,
            panelClass: ["status-snack-bar"],
          });
        } else {
          this.snackBar.open(this.translate.instant("serverErr"), "", {
            duration: 4000,
            panelClass: ["status-snack-bar"],
          });
        }
      }
    );
    }
    else{
      this.mpeService
      .editEstimateAndApply(planNumber, estUomUpdatePayload)
      .subscribe(
        (response) => {
          if (response && response.IsSuccess) {
            modalRef.close();
            this.editData(this.rowData);
          }
          else{
            modalRef.close();
            this.snackBar.open(this.translate.instant("unknownerr"), "", {
              duration: 4000,
              panelClass: ["status-snack-bar"],
            });

          }
    },
    (err)=>{
      modalRef.close();
      if (err.status === 0 || err.status == 504) {
        this.snackBar.open(this.translate.instant("networkError"), "", {
          duration: 4000,
          panelClass: ["status-snack-bar"],
        });
      } else {
        this.snackBar.open(this.translate.instant("serverErr"), "", {
          duration: 4000,
          panelClass: ["status-snack-bar"],
        });
      }
    }
  )
  }
}

  viewData(event, row, templateRef) {
    if (
      event.target.classList.contains("edit-container") ||
      event.target.classList.contains("edit-button")
    ) {
      if (row.Status !== "Finalized") {
        //this.editData(row);
        this.checkWgUom(row, templateRef);
      } else {
        this.previewEstimate(row);
      }
    } else {
      this.previewEstimate(row);
    }
  }

  previewEstimate(row) {
    if (!this.isMpe) {
      this.router.navigate([
        "/estimate/estimateDetails",
        {
          component: "estimateList",
          planNumber: row.PlanNumber,
          action: "view",
        },
      ]);
    } else {
      this.router.navigate([
        "/MPestimate/estimateDetails",
        {
          component: "estimateList",
          planNumber: row.PlanNumber,
          action: "view",
        },
      ]);
    }
  }

  fetchEstimates(isArchivedStatus) {
    this.dataService.showLoading = true;
    if (this.dataService.canAccessCalc) {
      this.estimateSubscribe = this.estimatePricingService
        .getEstimateList(isArchivedStatus)
        .subscribe(
          (data) => {
            this.estimateList = data.filter(
              (item) =>
                !item.Archived &&
                !item.IsDeleted &&
                item.DefinitionType !== this.mpeDefinitionType
            );
            this.estimateListBackUp = JSON.parse(
              JSON.stringify(this.estimateList)
            );
            this.multiProdEstimateListBackUp = data.filter(
              (item) =>
                !item.Archived &&
                !item.IsDeleted &&
                item.DefinitionType === this.mpeDefinitionType
            );
            const sortedData = this.sortByLastModifiedOn(this.estimateList);
            this.estimateList = sortedData.slice(0, 7);
            this.dataService.showLoading = false;
            this.showFetch = false;
            this.showLoad = false;
          },
          (err) => {
            this.showFetch = false;
            this.showLoad = false;
          }
        );
    } else {
      this.showLoad = false;
    }
  }

  ngOnDestroy() {
    if (this.estimateSubscribe) {
      this.estimateSubscribe.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.estimateEnumSubscribe) {
      this.estimateEnumSubscribe.unsubscribe();
    }
  }

  change() {
    this.valueInput = true;
  }
  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  cardUrlJson() {
    return {
      onBoarding:
        "https://caterpillar.sharepoint.com/teams/ServiceDataandProcessStandardsCommunity/SitePages/Getting-Started---Cat-SOC-(Service-Option-Collaborator)-and-Cat-Calculator-Web.aspx",
      quickEstimator:
        "https://caterpillar.sharepoint.com/teams/ServiceDataandProcessStandardsCommunity/SitePages/Quick-Estimator.aspx",
      calculatorWeb:
        "https://caterpillar.sharepoint.com/teams/ServiceDataandProcessStandardsCommunity/SitePages/Calculator-Web.aspx",
    };
  }
  openCardLink(event, cardLink?) {
    if (!event.target.classList.contains("email-text")) {
      if (event.target && event.target.href) {
        event.preventDefault();
        event.stopPropagation();
        window.open(event.target.href, "_blank");
      } else {
        if (cardLink) {
          event.preventDefault();
          event.stopPropagation();

          window.open(cardLink, "_blank");
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }
  }

  disableHover(row) {
    if (row.Status == "Finalized") {
      this.hover = true;
      this.editToolTip = this.translate.instant("view-tooltip");
    } else {
      this.hover = false;
      this.editToolTip = this.translate.instant("edit-tooltip");
    }
  }
  openReleaseNotes() {
    this.router.navigateByUrl("/release-notes");
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.files && changes.files.currentValue) {
      this.files = changes.files.currentValue;
      if (this.files.length > 0) {
        this.show = false;
      }
    }
  }
  onSelect(event) {
    this.sizelimit = false;
    this.files = [];
    if (_.find(event.rejectedFiles, { reason: "no_multiple" })) {
      this.multipleFilesUploaded = true;
      this.alert = false;
      this.show = false;
    } else if (_.find(event.rejectedFiles, { reason: "type" })) {
      this.show = false;
      this.alert = true;
      this.multipleFilesUploaded = false;
    } else if (_.find(event.rejectedFiles, { reason: "size" })) {
      this.sizelimit = true;
    } else {
      this.files.push(...event.addedFiles);
      this.show = false;
      this.handleFileSelect(this.files[0]);
    }
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.filename = "";
    this.show = true;
    this.alert = false;
    this.multipleFilesUploaded = false;
    this.imagePath = "";
    this.imageValue = "";
  }
  browse(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.filename = event.target.files[0].name;
    this.files.push(event.target.files[0].name);
    this.show = false;
  }
  handleFileSelect(file) {
    if (file) {
      const reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(event) {
    this.binaryString = event.target.result;
    this.base64textString = btoa(this.binaryString);
    this.getImage();
  }
  getImage() {
    this.trainingCardImage = "addNewCard";
    this.imagePath = "data:image/png;base64," + this.base64textString;
    this.imageValue = "data:image/png;base64," + this.base64textString;
  }
  resetall() {
    this.files = [];
    this.filename = "";
    this.show = true;
    this.alert = false;
    this.multipleFilesUploaded = false;
    this.inputdata = "";
    this.cardNavLink = "";
    this.addNewCardData = "";
    this.imagePath = "";
    this.imageValue = "";
    this.sizelimit = false;
    this.colordata = "";
    const classelement1 = document.getElementsByClassName("newCard");
    if (classelement1) {
      for (let i = 0; i < classelement1.length; i++) {
        const currentEl = classelement1[i] as HTMLDivElement;
        currentEl.style.backgroundColor = "#ffffff";
      }
    }
    const classelement2 = document.getElementsByClassName("get-started-card");
    if (classelement2) {
      for (let i = 0; i < classelement2.length; i++) {
        const currentEl = classelement2[i] as HTMLDivElement;
        currentEl.style.backgroundColor = "#F2EBE9";
      }
    }
    const classelement3 = document.getElementsByClassName(
      "create-estimate-card"
    );
    if (classelement3) {
      for (let i = 0; i < classelement3.length; i++) {
        const currentEl = classelement3[i] as HTMLDivElement;
        currentEl.style.backgroundColor = "#BFC9D1";
      }
    }
    const classelement4 = document.getElementsByClassName(
      "quick-estimator-card"
    );
    if (classelement4) {
      for (let i = 0; i < classelement4.length; i++) {
        const currentEl = classelement4[i] as HTMLDivElement;
        currentEl.style.backgroundColor = "rgba(255, 219, 74, 0.75)";
      }
    }
    if (this.colorpicker) {
      this.colorpicker.color = "";
    }
  }
  setBodyOverflow() {
    const bodyEle = document.getElementsByTagName("body");
    if (bodyEle && bodyEle[0]) {
      bodyEle[0].style.overflow = "hidden";
    }
  }
  removeBodyScroll() {
    const bodyEle = document.getElementsByTagName("body");
    if (bodyEle && bodyEle[0]) {
      bodyEle[0].style.overflow = "auto";
    }
  }
  editcard(data) {
    this.inputdata = data;
  }

  color(event) {
    this.colordata = event;
  }

  loadtrainingcard() {
    this.estimatePricingService
      .loadTrainingData("TrainingContentIndex")
      .subscribe((data) => {
        this.loaddata = data;
        if (data && data.length > 0) {
          this.loaddata = data[0];
          if (this.loaddata && this.loaddata.Content?.length > 0) {
            this.estimatePricingService
              .loadTrainingData(this.loaddata.Content, true)
              .subscribe((data: loadingDataI[]) => {
                this.loadspinner = false;
                this.trainingResourceCardsData = data;
                this.noContent = false;
                this.cardLoader = false;
              });
          } else {
            this.loadspinner = false;
            this.trainingResourceCardsData = [];
            this.noContent = true;
          }
        } else {
          this.loadspinner = false;
          this.trainingResourceCardsData = [];
          this.noContent = true;
        }
      });
  }

  sanitizeImage(urlString: string) {
    return `data:image/png;base64,${urlString}`;
  }
  revertImage() {
    this.imageValue = this.backupimage;
    this.base64textString = this.backupbasestring;
    this.getImage();
    this.files = [];
    this.show = true;
  }
  checkNav(): boolean {
    if (this.cardNavLink == "") {
      this.linkValidationMessage = this.translate.instant("invalid_nav");
      return true;
    }

    if (!this.cardNavLink.match(this.navPattern)) {
      this.linkValidationMessage =
        this.cardNavLink.indexOf("http://") == -1 &&
        this.cardNavLink.indexOf("https://") == -1
          ? this.translate.instant("lbl_link_http")
          : this.translate.instant("invalid_nav");
    } else {
      this.linkValidationMessage = "";
    }
  }

  getPlanSelectionValues() {
    this.estimateEnumSubscribe = this.estimatePricingService
      .getSelectionValue()
      .subscribe((data) => {
        this.estimatePricingService.planSelectionValues = data;
      });
  }
  onStartUsageBlur() {
    setTimeout(() => {
      if (Number(this.quickEstimteForm.controls.startUsage.value) !== 0) {
        this.quickEstimteForm.controls.startUsage.setValue(
          Number(this.quickEstimteForm.controls.startUsage.value).toString()
        );
        this.compareStartUsage();
      }
    }, 0);
    this.checkValidity(this.quickEstimteForm.controls.estimateName, 30);
    this.checkValidity(this.quickEstimteForm.controls.startUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.endUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.probfailure, 10);
    this.startUsage = true;
    this.checkForErrors("startUsage");
  }
  compareStartUsage() {
    this.startUsage = true;
    this.quickEstimateService.disableStartOver = false;

    if (this.quickEstimateService.completeEstimate.getValue()) {
      this.quickEstimateService.updatecurrentcompleteEstimate("");
    }

    this.valueInput = true;
    if (this.quickEstimteForm.controls.startUsage.value === "") {
      this.quickEstimteForm.controls.startUsage.setErrors({ required: true });
    } else if (
      this.quickEstimteForm.value.endUsage &&
      Number(this.quickEstimteForm.value.startUsage) >=
        Number(this.quickEstimteForm.value.endUsage)
    ) {
      this.quickEstimteForm.controls.startUsage.setErrors({
        invalidStartUsage: true,
      });
    } else if (
      this.quickEstimteForm.controls.startUsage?.errors &&
      this.quickEstimteForm.controls.startUsage?.errors.invalidStartUsage
    ) {
      this.quickEstimteForm.controls.startUsage.setErrors(null);
    } else if (
      this.quickEstimteForm.controls.endUsage?.errors &&
      this.quickEstimteForm.controls.endUsage?.errors.invalidEndUsage
    ) {
      this.quickEstimteForm.controls.endUsage.setErrors(null);
    }
    this.checkForErrors("startUsage");
  }
  compareStartEnd() {
    this.endUsage = true;
    this.quickEstimateService.disableStartOver = false;
    if (this.quickEstimateService.completeEstimate.getValue()) {
      this.quickEstimateService.updatecurrentcompleteEstimate("");
    }
    this.valueInput = true;
    if (this.quickEstimteForm.controls.endUsage.value === "") {
      this.quickEstimteForm.controls.endUsage.setErrors({ required: true });
    } else if (
      Number(this.quickEstimteForm.value.startUsage) >=
      Number(this.quickEstimteForm.value.endUsage)
    ) {
      this.quickEstimteForm.controls.endUsage.setErrors({
        invalidEndUsage: true,
      });
    } else if (
      this.quickEstimteForm.controls.endUsage?.errors &&
      this.quickEstimteForm.controls.endUsage?.errors.invalidEndUsage
    ) {
      this.quickEstimteForm.controls.endUsage.setErrors(null);
    } else if (
      this.quickEstimteForm.controls.startUsage?.errors &&
      this.quickEstimteForm.controls.startUsage?.errors.invalidStartUsage
    ) {
      this.quickEstimteForm.controls.startUsage.setErrors(null);
    }
    this.checkForErrors("endUsage");
  }
  onEndUsageBlur() {
    setTimeout(() => {
      if (Number(this.quickEstimteForm.controls.endUsage.value) !== 0) {
        this.quickEstimteForm.controls.endUsage.setValue(
          Number(this.quickEstimteForm.controls.endUsage.value).toString()
        );
        this.compareStartEnd();
      }
    }, 0);
    this.checkValidity(this.quickEstimteForm.controls.estimateName, 30);
    this.checkValidity(this.quickEstimteForm.controls.startUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.endUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.probfailure, 10);
    this.endUsage = true;
    this.checkForErrors("endUsage");
  }
  checkValidity(formgroup, priority) {
    const index = this.validForms.indexOf(formgroup);
    if (formgroup?.errors === null && formgroup.value !== "") {
      if (index === -1) {
        formgroup.priority = priority;
        this.validForms.push(formgroup);
      }
      return true;
    } else {
      if (index !== -1) {
        this.validForms.splice(index, 1);
      }
      return false;
    }
  }
  checkForErrors(formControl) {
    if (this.quickEstimteForm.controls[formControl]?.errors) {
      const ele = document.getElementById(formControl);
      if (ele) {
        ele.classList.remove("ng-valid");
        ele.classList.add("ng-invalid", "ng-touched");
      }
    } else {
      const ele2 = document.getElementById(formControl);
      if (ele2) {
        ele2.classList.remove("ng-invalid", "ng-touched");
        ele2.classList.add("ng-valid");
      }
    }
  }
  onEstimateNameBlur() {
    this.estimateName = true;
    this.quickEstimateService.disableStartOver = false;
    if (this.quickEstimteForm.controls.estimateName.value === "") {
      this.quickEstimteForm.controls.estimateName.setErrors({ required: true });
    }
    if (this.quickEstimateService.completeEstimate.getValue()) {
      this.quickEstimateService.updatecurrentcompleteEstimate("");
    }

    this.checkValidity(this.quickEstimteForm.controls.estimateName, 30);
    this.checkValidity(this.quickEstimteForm.controls.startUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.endUsage, 20);
    this.checkValidity(this.quickEstimteForm.controls.probfailure, 10);
    this.estimateName = true;
    this.checkForErrors("estimateName");
  }

  switchTab(e) {
    this.estimateTypeTab = e.target.id;
    this.changeTabStyle();
    const tab = document.getElementById(this.estimateTypeTab);
    tab.classList.add("active");
    if (this.estimateTypeTab === "nav-standard-tab") {
      this.isMpe = false;
    } else {
      this.isMpe = true;
    }
    if (this.estimateTypeTab === "nav-standard-tab") {
      this.setStandardEstimateData();
    } else if (this.estimateTypeTab === "nav-mpe-tab") {
      this.setMpeData();
    }
    this.setTableColumns();
  }

  changeTabStyle() {
    const elements = document.getElementsByClassName("estimate-type-tab");
    Object.keys(elements).forEach((key) => {
      const item = elements[key];
      item.className = item.className.replace(/\bactive\b/g, "");
    });
  }

  setMpeData() {
    const sortedData = this.sortByLastModifiedOn(
      this.multiProdEstimateListBackUp
    );
    this.estimateList = sortedData.slice(0, 7);
    this.dataService.showLoading = false;
    this.showFetch = false;
    this.showLoad = false;
  }

  setStandardEstimateData() {
    const sortedData = this.sortByLastModifiedOn(this.estimateListBackUp);
    this.estimateList = sortedData.slice(0, 7);
    this.dataService.showLoading = false;
    this.showFetch = false;
    this.showLoad = false;
  }

  setTableColumns() {
    if (this.estimateTypeTab === "nav-standard-tab") {
      this.addProductColumns();
    } else if (this.estimateTypeTab === "nav-mpe-tab") {
      this.removeModelColumns();
      this.removeSerialColumns();
    }
  }

  removeModelColumns() {
    this.displayedColumns.forEach((ele, index, arr) => {
      if (ele === "model") {
        arr.splice(index, 1);
      }
    });
  }

  removeSerialColumns() {
    this.displayedColumns.forEach((ele, index, arr) => {
      if (ele === "serialNoPrefix") {
        arr.splice(index, 1);
      }
    });
  }

  addProductColumns() {
    let modelIndex = this.displayedColumns.indexOf("model");
    let serialPrefixIndex = this.displayedColumns.indexOf("serialNoPrefix");

    if (modelIndex === -1) {
      this.displayedColumns.splice(1, 0, "model");
    }
    if (serialPrefixIndex === -1) {
      this.displayedColumns.splice(2, 0, "serialNoPrefix");
    }
  }
}
